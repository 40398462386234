import React, { ChangeEvent } from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { NotificationFormProps } from 'components/SupplierCard/ToDoList/NotificationForm/types';
import cn from './NotificationForm.module.scss';

export const NotificationForm = React.memo(({
  enable,
  startTime,
  endTime,
  notificationTime,
  onEnableChange,
  onStartDateChange,
  onEndDateChange,
  onNotificationTimeChange
}: NotificationFormProps) => {
  return (
    <div className={cn.root}>
      <FormControlLabel
        label="Enable notification"
        control={
          <Checkbox checked={enable} onChange={(event: any, checked: boolean): void => onEnableChange(checked)} />
        }
      />
      <KeyboardTimePicker
        disabled={!enable}
        open={false}
        ampm={false}
        label="Start time"
        value={startTime}
        onChange={(date): void => onStartDateChange(date as Date)}
        className={cn.input}
      />
      <KeyboardTimePicker
        disabled={!enable}
        open={false}
        ampm={false}
        label="End time"
        value={endTime}
        onChange={(date): void => onEndDateChange(date as Date)}
        className={cn.input}
      />
      <TextField
        disabled={!enable}
        label="Notification Time"
        value={notificationTime}
        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
          onNotificationTimeChange(parseInt(event.target.value))}
      />
    </div>
  )
});
