import { User } from 'types/User';
import { Selector } from '../StoreConfig';

export const selectUser: Selector<User | null> = (state) => {
  return state.session.user;
};

export const selectUserInitials: Selector<string> = (state) => {
  const { user } = state.session;
  if (!user || user === null) {
    return '';
  }
  return user.name
    .split(/[ .]+/)
    .reduce((name: string, nextNamePart: string) => `${name}${nextNamePart.charAt(0).toUpperCase()}`,
      '');
};

export const selectUserName: Selector<string> = (state) => {
  return state.session.user ? state.session.user.name : '';
};

export const selectAuthenticatedMicrosoft: Selector<boolean> = (state => {
  return state.session.authenticatedMicrosoft;
});
