import { Reducer } from 'redux';
import { ActionTypes, ResponsibleSalesAction, ResponsibleSalesState } from './types';

export const initialState: ResponsibleSalesState = [];

export const ResponsibleSalesReducer: Reducer<ResponsibleSalesState, ResponsibleSalesAction> = (
  state = initialState, action,
): ResponsibleSalesState => {
  if (action.type === ActionTypes.SET_RESPONSIBLE_SALES) {
    return action.responsibleSales;
  }
  return state;
};
