import { Action, applyMiddleware, compose, createStore, StoreCreator } from 'redux';
import thunkMiddleware, { ThunkAction as ReduxThunkAction, ThunkDispatch as ReduxDispatch } from 'redux-thunk';
import { AppState, rootReducer } from './RootReducer';

export type ThunkAction<R, A extends Action> = ReduxThunkAction<Promise<R> | R, AppState, null, Action>;
export type ThunkDispatch = ReduxDispatch<AppState, any, Action>;
export type GetState = () => AppState;

export type Selector<R> = (state: AppState) => R;

export const configureStore = (): StoreCreator => {
  const middleware = [
    thunkMiddleware,
  ];

  // @ts-ignore
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware)),
  );
};
