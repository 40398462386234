import React, { useEffect, useState } from 'react';
import cn from "../../containers/MyPage/MyPage.module.scss";
import { Tab, Tabs } from "@material-ui/core";
import { MyPageTable } from "../common/myPageTable/MyPageTable";
import { ToDoCrm } from "../../store/toDoList/types";
import { finishLoading, startLoading } from "../../hooks/useProgress";
import api from "../../api";

type MyPageData = {
    activeTasks: ToDoCrm[];
    doneTasks: ToDoCrm[];
};


export const MyCrmTasks = () => {
    const [selectedTab, setSelectedTab] = useState(1);
    const [data, setData] = useState<MyPageData>(
        {
            activeTasks: [], doneTasks: [],
        },

    );

    useEffect(() => {
        try {
            startLoading();
            (async (): Promise<void> => {
                let data = await api.getMyCrmTasks();
                setData(data);
            })();
        } catch (err) {
        }
        finishLoading();
    }, []);

    const doneTitle = selectedTab === 1 ? 'Scheduled on' : 'Done';

    return (
        <div>
            <div className={cn.tableTitle}>MY CRM TASKS</div>
            <div>
                <Tabs
                    value={selectedTab}
                    onChange={(event, value): void => setSelectedTab(value)}
                    variant="fullWidth"
                    indicatorColor="primary"
                    classes={{
                        root: cn.tabsRoot,
                    }}
                >
                    <Tab label="Active" value={1} classes={{ root: cn.tab }} />
                    <Tab label="Done" value={0} classes={{ root: cn.tab }} />
                </Tabs>
                <MyPageTable
                    className={cn.table}
                    tableData={selectedTab === 1 ? data.activeTasks : data.doneTasks}
                    columns={[
                        { name: 'customerCard', title: 'Customer Name' },
                        { name: 'date', title: doneTitle },
                        { name: 'comment', title: 'Task' },
                    ]}
                    columnExtensions={[
                        { columnName: 'customerCard', width: 270 },
                        { columnName: 'comment' },
                        { columnName: 'date', width: 150 },
                    ]}
                    defaultSorting={[
                        { columnName: 'date', direction: 'asc' },
                    ]}
                    data-role="task-table"
                />
            </div>
        </div>
    )
}