import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField,
} from '@material-ui/core';

import { ContactPerson } from 'components/SupplierCard/Tabs/ContactPersonsTab/types';
import { MapBoolean } from 'types/types';
import { startLoading, finishLoading } from 'hooks/useProgress';
import cn from './ContactPersonModal.module.scss';

type ContactPersonModalProps = {
  isOpen: boolean;
  onClose: () => void;
  editedRow?: ContactPerson | null;
  onSubmit: (contactPerson: ContactPerson) => void;
};

type Errors = Partial<MapBoolean<ContactPerson>>;

const fields = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE_NUMBER: 'telephone',
  TITLE: 'title',
  IS_MY_BEGOMA: 'isMyBegoma',
};

const MyBegomaErrors = {
  DuplicateUserName: 'My Begoma user with such email already exists.',
  InvalidUserName: 'Invalid My Begoma email.'
};

export const ContactPersonModal = React.memo(({
  isOpen,
  editedRow,
  onClose,
  onSubmit,
}: ContactPersonModalProps) => {
  const initialFormData: ContactPerson = {
    id: 0,
    name: '',
    email: '',
  };

  const [formData, setFormData] = useState<ContactPerson>(initialFormData);
  const [errors, setErrors] = useState<Errors>({});
  const [myBegomaError, setMyBegomaError] = useState<string | null>(null);

  function handleFieldChange(event: ChangeEvent<HTMLInputElement>, field: string): void {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
    if (field === fields.NAME || field === fields.EMAIL) {
      setErrors({
        ...errors,
        [field]: false,
      });
    }
    if (field === fields.EMAIL) {
      setMyBegomaError(null);
    }
  }

  function handleCheckboxChange(checked: boolean, field: string): void {
    setFormData({
      ...formData,
      [field]: checked,
    });
  }

  function validate(): boolean {
    const isError = !formData.name || !isEmailValid(formData.email);
    setErrors({
      name: !formData.name,
      email: !isEmailValid(formData.email)
    });
    return isError;
  }

  function isEmailValid(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  async function handleSave(): Promise<void> {
    startLoading();
    try {
      const isError = validate();
      if (isError) {
        finishLoading();
        return;
      }
      await onSubmit(formData);
    } catch (err) {
      if ((err as any).statusCode === 400) {
        const { code }: { code: keyof typeof MyBegomaErrors } = (err as any).response?.data[0];
        setMyBegomaError(MyBegomaErrors[code]);
        setErrors({
          ...errors,
          email: true,
        });
      }
    }
    finishLoading();
  }

  function handleClose(): void {
    onClose();
  }

  useEffect(() => {
    if (editedRow) {
      setFormData(editedRow);
    }
  }, [editedRow]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="addNewAddressModal"
      maxWidth="xs"
      className={cn.dialog}
    >
      <DialogTitle id="addNewAddressModal">{`${editedRow ? 'Edit' : 'Add'} Contact Person`}</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={formData.name}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => handleFieldChange(event, fields.NAME)}
          error={errors.name}
          helperText={errors.name ? 'The name is required' : ''}
          inputProps={{ maxLength: 50 }}
          fullWidth
          variant="outlined"
          classes={{
            root: cn.input,
          }}
        />
        <TextField
          label="Email"
          value={formData.email}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => handleFieldChange(event, fields.EMAIL)}
          error={errors.email}
          helperText={errors.email ? 'Invalid email format' : ''}
          inputProps={{ maxLength: 50 }}
          fullWidth
          variant="outlined"
          classes={{
            root: cn.input,
          }}
        />
        {myBegomaError && (
          <div className={cn.error}>{myBegomaError}</div>
        )}
        <TextField
          label="Phone Number"
          value={formData.telephone || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => handleFieldChange(event, fields.PHONE_NUMBER)}
          inputProps={{ maxLength: 50 }}
          fullWidth
          variant="outlined"
          classes={{
            root: cn.input,
          }}
        />
        <TextField
          label="Title"
          value={formData.title || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => handleFieldChange(event, fields.TITLE)}
          inputProps={{ maxLength: 50 }}
          fullWidth
          variant="outlined"
          classes={{
            root: cn.input,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          color="primary"
        >
          Save
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
});

