import { Table } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import api from 'api';
import { ToDoListBody } from 'components/SupplierCard/ToDoList/ToDoListBody/ToDoListBody';
import { ToDoListForm } from 'components/SupplierCard/ToDoList/ToDoListForm/ToDoListForm';
import { updateNextToDo as updateNextToDoAction } from 'store/suppliersList/ActionCreator';
import { handleLogsDownload as handleLogsDownloadAction } from 'store/logs/ActionCreator';
import { selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { ToDo } from 'store/toDoList/types';
import { SupplierId } from 'store/suppliersList/types';
import { AppState } from 'store/RootReducer';
import cn from './ToDoList.module.scss';

type MapStateToProps = {
  selectedSupplierId: SupplierId;
  userName: string;
}

type MapDispatchToProps = {
  downloadSupplierLog: (supplierId: SupplierId) => void;
  updateNextToDo: (supplierId: SupplierId) => void;
}

type ToDoListProps = MapStateToProps & MapDispatchToProps;

const ToDoListComponent = React.memo(({
  selectedSupplierId, userName, downloadSupplierLog, updateNextToDo,
}: ToDoListProps) => {
  function handleAddToDoButton(): void {
    updateNextToDo(selectedSupplierId);
  }

  async function handleSave(toDo: ToDo, comment: string): Promise<void> {
    try {
      updateNextToDo(selectedSupplierId);
      const addedLog = {
        createdAt: format(new Date(), 'yyyy-MM-dd HH:mm'),
        createdBy: userName,
        supplierCardId: selectedSupplierId,
        comment: `Task description: '${toDo.comment}' is done. ${comment !== '' ? 'Comment: ' : ''}${comment}`,
      };
      await api.addSupplierLog(selectedSupplierId, addedLog);
      downloadSupplierLog(selectedSupplierId);
    } catch (err) {
    }
  }

  return (
    <div className={cn.root}>
      <div className={cn.header}>TO DO LIST</div>
      <div className={cn.mainBlock}>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="70%" />
            <col width="1%" />
          </colgroup>
          <ToDoListForm onToDoAdd={handleAddToDoButton} />
          <ToDoListBody onToDoDone={handleSave} />
        </Table>
      </div>
    </div>
  );
});

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedSupplierId: selectSelectedSupplierId(state),
  userName: state.session.user.name,
});

const mapDispatchToProps: MapDispatchToProps = {
  downloadSupplierLog: handleLogsDownloadAction,
  updateNextToDo: updateNextToDoAction,
};

export const ToDoList = connect(mapStateToProps, mapDispatchToProps)(ToDoListComponent);
