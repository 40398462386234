import React from 'react';
import PropTypes from 'prop-types';
import {
  SortingState,
  IntegratedSorting,
  FilteringState,
  IntegratedFiltering,
  SummaryState,
  IntegratedSummary,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { numberWithSpaces } from '../../../../utils/string';
import cn from './Profitability.module.scss';

const Cell = (props) => (
  <Table.Cell
    {...props}
    style={{
      fontFamily: 'Roboto',
      fontSize: '17px',
      fontWeight: 'normal',
      color: '#000000',
      paddingLeft: '17px',
      borderTop: 'solid 1px #0072ba',
      borderBottom: 'none',
    }}
  />
);

const HeaderCell = (props) => (
  <TableHeaderRow.Cell
    {...props}
    style={{
      position: 'sticky',
      top: 0,
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#0072ba',
      paddingLeft: '16px',
      borderBottom: 'none',
      backgroundColor: '#ffffff',
    }}
  />
);

const FilterCell = (props) => {
  return (
  <TableFilterRow.Cell
    {...props}
    style={{
      position: 'sticky',
      top: 57.77,
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      paddingRight: '4px',
      paddingLeft: '8px',
      fontSize: '14px',
      color: '#0072ba',
      borderTop: 'solid 1px #0072ba',
      borderBottom: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: '#ffffff',
      outline: 'none'
    }}
    >
    <div
      className={cn.filterInput}
      style={{
        margin: '8px 0 8px 8px',
        width: '100%'
      }}> 
      <FilterCellInternal {...props} />     
    </div>
  </TableFilterRow.Cell>);
};

const FilterCellInternal = ({ filter, onFilter }) => (
  <input
    type="search"
    placeholder='Filter...'
    value={filter ? filter.value : ''}
    onChange={e => onFilter(e.target.value ? { value: e.target.value } : null)}
    style={{
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: '14px',
        width: '100%',
        display: 'flex',
        padding: '6px 0px 7px',
        border: 'none',
        outline: 'none'
    }}
  />
);

const FooterCell = (props) => (
  <Table.Cell
    {...props}
    style={{
      paddingLeft: '17px',
      borderTop: 'solid 2px #000000',
      borderBottom: 'solid 1px #0072ba',
    }}
  />
);

const NoDataCell = (props) => (
  <Table.NoDataCell
    {...props}
    className={cn.noDataCell}
    style={{        
      borderTop: 'solid 1px #0072ba',
      borderBottom: 'none',
    }}
  />
);

const FooterItem = ({ children }) => (
  <div
    style={{
      fontFamily: 'Roboto',
      fontSize: '17px',
      fontWeight: 'bold',
      color: '#0072ba',
    }}
  >
    {children}
  </div>
);

FooterItem.propTypes = {
  children: PropTypes.node,
};

const TableContainer = (props) => (
  <Table.Container
    {...props}
    style={{
      ...props.style,
      overflow: 'visible',
    }}
  />
);

const PercentFormatter = ({ value }) => (value < 0 ? `${value.toString().replace('-', '- ')}%` : `${value}%`);

const NumberFormatter = ({ value }) => numberWithSpaces(value);

const NumberTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={NumberFormatter}
    {...props}
  />
);

const PercentTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={PercentFormatter}
    {...props}
  />
);

export const ProfitabilityTab = ({
  tabData,
}) => {
  const getCustomTotal = (type, rows, getValue) => {
    if (type === 'totalPercent') {
      const totalTb1 = rows.reduce((sum, row) => sum + row.tb1, 0);
      const totalTurnover = rows.reduce((sum, row) => sum + row.turnover, 0);
      return totalTurnover;
    }
    if (type === 'grandTotal') {
      return 'Grand Total [EUR]:';
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
  };
  return (
    <div>
      <Grid
        rows={tabData}
        columns={[
          { name: 'businessArea', title: 'Business Area' },
          { name: 'turnover', title: 'Turnover [EUR]' },
          { name: 'nrOfShipments', title: 'Nr of Shipment', align: 'right'},
        ]}
      >
        <PercentTypeProvider
          for={['percent']}
        />
        <NumberTypeProvider
          for={['turnover', 'nrOfShipments']}
        />
        <SortingState defaultSorting={[{ columnName: 'businessArea', direction: 'asc' }]} />
        <IntegratedSorting />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <SummaryState
          totalItems={[
            { columnName: 'businessArea', type: 'grandTotal' },
            { columnName: 'turnover', type: 'sum' },
            { columnName: 'nrOfShipments', type: 'sum' },
          ]}
        />
        <IntegratedSummary
          calculator={getCustomTotal}
        />
        <Table
          containerComponent={TableContainer}
          cellComponent={Cell}
          noDataCellComponent={NoDataCell}
          columnExtensions={[
            {
              columnName: 'turnover',
              align: 'right',
            },
            {
              columnName: 'nrOfShipments',
              align: 'right',
            },
          ]}
        />
        <TableHeaderRow
          showSortingControls
          cellComponent={HeaderCell}
              />
        <TableFilterRow
          cellComponent={FilterCell}
        />
        <TableSummaryRow
          totalCellComponent={FooterCell}
          itemComponent={FooterItem}
              />
      </Grid>
    </div>
  );
};

ProfitabilityTab.propTypes = {
  tabData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
