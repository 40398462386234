import axios from 'axios';

const getToken = () => {
  if (JSON.parse(localStorage.getItem('user'))) {
    return JSON.parse(localStorage.getItem('user')).token;
  }
  return '';
};

export const setupInterceptors = (logout, removeCookies, showSnackbar) => {
  const requestInterceptor = axios.interceptors.request.use(config => ({
    ...config,
    headers: {
      ...config.headers,
      common: {
        ...config.headers.common,
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    baseURL: process.env.REACT_APP_API_HOST,
  }));

  const responseInterceptor = axios.interceptors.response.use(
    response => response,
    (error) => {
      if (error?.response?.status === 401) {
        logout();
        removeCookies();
      } else {
        showSnackbar({ message: 'Oops! Something went wrong. Please try later.', type: 'error'});
      }
      return Promise.reject(error);
    },
  );

  return {
    requestInterceptor,
    responseInterceptor,
  };
};

export const cleanupInterceptors = (interceptors) => {
  interceptors.forEach(interceptor => axios.interceptors.request.eject(interceptor));
};
