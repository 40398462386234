const formatDate = (date) => {
  const parsedDate = new Date(Date.parse(date));
  return parsedDate.toLocaleDateString('zh-Hans-CN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).replace(/\//g, '-');
};

const formatTime = date => `${date.getHours()}:${date.getMinutes()}`;

const getCurrentDateTime = () => {
  const today = new Date();
  const date = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
  const time = `${today.getHours()}:${today.getMinutes()}`;
  return `${date} ${time}`;
};

export const generateGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

const DateService = {
  formatDate,
  formatTime,
  getCurrentDateTime,
  generateGuid,
};

export default DateService;
