import React, { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { TreeItem } from '@material-ui/lab';
import {
  ChevronRight, DeleteForever, CloudDownload, Folder as FolderIcon,
} from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import FileIcon from 'img/file.svg';
import { UploadButton } from 'components/SupplierCard/Tabs/FilesTab/UploadButton/UploadButton';
import { FileTypesIcons } from 'constants/FileTypesIcons';
import { DriveItem, useFiles } from 'hooks/useFiles';
import { ConfirmRenameModal } from 'components/SupplierCard/Tabs/FilesTab/ConfirmRenameModal/ConfirmRenameModal';
import { CreateFolderModal } from 'components/SupplierCard/Tabs/FilesTab/CreateFolderModal/CreateFolderModal';
import { CreateFolderButton } from 'components/SupplierCard/Tabs/FilesTab/CreateFolderButton/CreateFolderButton';
import { ConfirmDeleteModal } from 'components/SupplierCard/Tabs/FilesTab/ConfirmDeleteModal/ConfirmDeleteModal';
import { showSnackbar } from 'hooks/useSnackbar';
import cn from './CustomizedTreeItem.module.scss';

type CustomizedTreeItemProps = {
  item: DriveItem;
  nodeId?: number;
  expandLeaf?: (id: string) => void;
  isRoot?: boolean;
  fetchParentFiles?: () => Promise<void>;
};

export const CustomizedTreeItem = React.memo(
  ({
    item, expandLeaf, isRoot, fetchParentFiles,
  }: CustomizedTreeItemProps) => {
    const {
      getFiles, downloadFile, deleteFile, uploadFile, createFolderById,
    } = useFiles();
    const [children, setChildren] = useState<Array<DriveItem>>([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [uploadingFile, setUploadingFile] = useState<File>();
    const [newFileName, setNewFileName] = useState('');
    const [deletingItems, setDeletingItems] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<DriveItem | null>();
    const [createFolderOpen, setCreateFolderOpen] = useState<Element | null>();

    const fetchFiles = async (): Promise<void> => {
      setIsLoading(true);
      const files = await getFiles(item.id);
      setChildren(files);
      setIsLoading(false);
    };

    const handleFileUpload = async (file: File): Promise<void> => {
      try {
        setUploadedFiles([...uploadedFiles, file]);
        if (expandLeaf) {
          expandLeaf(item.id.toString());
        }
        await uploadFile(item.id, file);
        setUploadedFiles(uploadedFiles.filter((file) => file.name !== file.name));
        fetchFiles();
      } catch (err) {
      }
    };

    const handleChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
      const { files: inputFiles } = event.target;
      if (!inputFiles || inputFiles.length === 0) {
        return;
      }
      const file = Array.from(inputFiles || [])[0];
      if (children.find((ch) => ch.name === file.name)) {
        let i = 1;
        const nameParts = file.name.split('.');
        const format = nameParts.pop();
        let newName = `${nameParts.join('.')}_(${i}).${format}`;
        while (children.find((ch) => ch.name === newName)) {
          newName = `${nameParts.join('.')}_(${++i}).${format}`;
        }
        setNewFileName(newName);
        setUploadingFile(file);
        setConfirmOpen(true);
        return;
      }

      handleFileUpload(file);
    };

    const handleDeleteFile = async (item: DriveItem): Promise<void> => {
      try {
        setDeletingItems([...deletingItems, item.id]);
        await deleteFile(item);
        if (item.folder && fetchParentFiles) {
          fetchParentFiles();
        } else {
          fetchFiles();
        }
      } catch (err) {
      }
    };

    const handleDelete = (item: DriveItem, event: React.MouseEvent): void => {
      event.stopPropagation();
      if (item.folder && children && children.length > 0) {
        showSnackbar({
          message: 'Please remove all files from the folder before deleting it.',
          type: 'error',
        });
      } else {
        setConfirmDeleteOpen(item);
      }
    };

    const handleFolderCreate = async (folderName: string): Promise<void> => {
      if (expandLeaf) {
        expandLeaf(item.id.toString());
      }
      await createFolderById(item.id, folderName);
      setCreateFolderOpen(null);
      fetchFiles();
    };

    const handleConfirmClose = (): void => {
      setConfirmOpen(false);
      setUploadingFile(undefined);
      setNewFileName('');
    };

    useEffect(() => {
      if (item) {
        fetchFiles();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.id]);

    if (item.file) {
      const Icon = FileTypesIcons[item.file.mimeType as keyof typeof FileTypesIcons] || FileIcon;
      return (
        <TreeItem
          nodeId={`${item.id}_file`}
          key={`${item.id}_file`}
          label={(
            <div className={classNames(cn.row, cn.file)}>
              <img src={Icon} className={cn.imageIcon} alt={item.file.mimeType || ''} />
              <div className={cn.fileName}>{item.name}</div>
              {deletingItems.indexOf(item.id) !== -1 ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <div className={cn.iconsGroup}>
                  <CloudDownload
                    className={cn.icon}
                    htmlColor="#0072ba"
                    onClick={(): Promise<void> => downloadFile(item)}
                  />
                  <DeleteForever htmlColor="#f44336" onClick={(event): void => handleDelete(item, event)} />
                </div>
              )}
            </div>
          )}
        />
      );
    }

    return (
      <>
        <TreeItem
          nodeId={`${item.id}`}
          collapseIcon={<ChevronRight className={cn.collapseIcon} />}
          expandIcon={<ChevronRight />}
          label={(
            <div className={classNames(cn.row, cn.folder)}>
              <div className={cn.iconsGroup}>
                <FolderIcon className={cn.icon} htmlColor="#ffcc00" />
              </div>
              <div className={cn.fileName}>{item.name}</div>
              {isLoading && (<CircularProgress size={20} />)}
              <UploadButton
                onChange={handleChange}
                id={item.id}
                className={cn.icon}
              />
              <CreateFolderButton
                onClick={(event: ChangeEvent<HTMLDivElement>): void => setCreateFolderOpen(event.currentTarget)}
                className={!isRoot ? cn.icon : ''}
              />
              {!isRoot && (
                <DeleteForever htmlColor="#f44336" onClick={(event): void => handleDelete(item, event)} />
              )}
            </div>
          )}
        >
          <div>
            {children && children.length > 0
            && children.filter((child) => child.file).map((file) => {
              if (!file.file) {
                return null;
              }
              const Icon = FileTypesIcons[file.file.mimeType as keyof typeof FileTypesIcons] || FileIcon;
              return (
                <TreeItem
                  nodeId={`${file.id}`}
                  key={`${file.id}_file`}
                  label={(
                    <div className={classNames(cn.row, cn.file)}>
                      <img src={Icon} className={cn.imageIcon} alt={file.file.mimeType || ''} />
                      <div className={cn.fileName}>{file.name}</div>
                      {deletingItems.indexOf(file.id) !== -1 ? (
                        <CircularProgress color="secondary" size={20} />
                      ) : (
                        <div className={cn.iconsGroup}>
                          <a className={cn.link} href={file.webUrl} target="_blank" rel="noreferrer noopener">View</a>
                          <CloudDownload
                            className={cn.icon}
                            htmlColor="#0072ba"
                            onClick={(): Promise<void> => downloadFile(file)}
                          />
                          <DeleteForever htmlColor="#f44336" onClick={(event): void => handleDelete(file, event)} />
                        </div>
                      )}
                    </div>
                  )}
                />
              );
            })}
            {uploadedFiles.map((file) => {
              const Icon = FileTypesIcons[file.type as keyof typeof FileTypesIcons] || FileIcon;
              return (
                <TreeItem
                  nodeId={`${file.name}`}
                  key={`${file.name}_file`}
                  label={(
                    <div className={classNames(cn.row, cn.file)}>
                      <img src={Icon} className={cn.imageIcon} alt={file.type || ''} />
                      <div className={cn.fileName}>{file.name}</div>
                      <div className={cn.iconsGroup}>
                        <CircularProgress size={20} />
                      </div>
                    </div>
                  )}
                />
              );
            })}
            {children && children.filter((child) => child.folder).map((folder) => (
              <CustomizedTreeItem
                item={folder}
                key={folder.id}
                expandLeaf={expandLeaf}
                fetchParentFiles={fetchFiles}
              />
            ))}
          </div>
        </TreeItem>
        {confirmOpen && (
          <ConfirmRenameModal
            open={confirmOpen}
            file={uploadingFile}
            newFileName={newFileName}
            onSubmit={handleFileUpload}
            onClose={handleConfirmClose}
          />
        )}
        {confirmDeleteOpen && (
          <ConfirmDeleteModal
            open={!!confirmDeleteOpen}
            item={confirmDeleteOpen}
            onSubmit={handleDeleteFile}
            onClose={(): void => setConfirmDeleteOpen(null)}
          />
        )}
        {createFolderOpen && (
          <CreateFolderModal
            anchorEl={createFolderOpen}
            onSubmit={handleFolderCreate}
            onClose={(): void => setCreateFolderOpen(null)}
          />
        )}
      </>
    );
  },
);
