import { Selector } from '../StoreConfig';
import { SortAsc, SortColumnId, Supplier, SupplierId, SuppliersList } from './types';

export const selectSuppliersList: Selector<SuppliersList> = (state) => state.suppliersList.supplierList;

export const selectSelectedSupplier: Selector<Supplier | undefined> = (state) => state.suppliersList.supplierList.find(
  (supplier: Supplier) => supplier.id === state.suppliersList.selectedSupplierId,
);

export const selectSelectedSupplierId: Selector<SupplierId> = (state) => state.suppliersList.selectedSupplierId;

export const selectSelectedSortColumnId: Selector<SortColumnId> = (state) => state.suppliersList.selectedSortColumnId;

export const selectSelectedSortAsc: Selector<SortAsc> = (state) => state.suppliersList.selectedSortAsc;
