import React, { CSSProperties, ReactElement } from 'react';
import { List } from 'react-virtualized';

type VirtualizedMenuListProps = {
  children: ReactElement | ReactElement[];
}

export const VirtualizedMenuList = ({ children }: VirtualizedMenuListProps): ReactElement => {
  const rows = React.Children.toArray(children);
  const rowRenderer = ({ key, index, style }: { key: string | number; index: number; style?: CSSProperties}) => (
    <div key={key} style={style}>{rows[index]}</div>
  );

  return (
    <List
      style={{ width: '100%' }}
      width={1000}
      height={rows.length > 8 ? 200 : 30 * rows.length}
      rowHeight={30}
      rowCount={rows.length}
      rowRenderer={rowRenderer}
    />
  );
};
