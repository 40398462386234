import { Action } from 'redux';
import { Supplier } from 'store/suppliersList/types';

export interface ToDo {
  id: string;
  comment: string;
  createdAt: string;
  createdBy: string;
  supplierCardId: string;
  date: string;
  done: boolean;
  supplierCard?: Supplier | null;
  mentions?: string[];
}

export interface ToDoClaim {
  id: string;
  comment: string;
  createdAt: string;
  createdBy: string;
  claimCardId: string;
  date: string;
  done: boolean;
  claimCard?: any | null;
  mentions?: string[];
}

export interface ToDoCrm {
  id: string;
  comment: string;
  createdAt: string;
  createdBy: string;
  supplierCardId: string;
  date: string;
  done: boolean;
  supplierCard?: any | null;
  mentions?: string[];
}

export interface ToDoSrm {
  id: string;
  comment: string;
  createdAt: string;
  createdBy: string;
  supplierCardId: string;
  date: string;
  done: boolean;
  supplierCard?: any | null;
  mentions?: string[];
}

export type ToDoList = Array<ToDo>;

export type ToDoListState = ToDoList;

export enum ToDoListActionTypes {
  SET_TO_DO_LIST = 'SET_TO_DO_LIST',
  ADD_TO_DO = 'ADD_TO_DO',
  COMPLETE_TO_DO = 'COMPLETE_TO_DO',
}

export interface SetToDoListAction extends Action<typeof ToDoListActionTypes.SET_TO_DO_LIST> {
  list: ToDoList;
}

export interface AddToDoAction extends Action<typeof ToDoListActionTypes.ADD_TO_DO> {
  toDo: ToDo;
}

export interface CompleteToDoAction extends Action<typeof ToDoListActionTypes.COMPLETE_TO_DO> {
  toDo: ToDo;
}

export type ToDoListAction = SetToDoListAction | AddToDoAction | CompleteToDoAction;
