import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { CustomTreeView } from 'components/SupplierCard/Tabs/FilesTab/CustomTreeView/CustomTreeView';
import { Button } from 'components/common/Button/Button';
import { selectSelectedSupplier, selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { useFiles } from 'hooks/useFiles';
import { selectIsAuthenticated } from 'store/microsoftGraph/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import { showSnackbar } from 'hooks/useSnackbar';
import cn from './FilesTab.module.scss';

// eslint-disable-next-line react/prop-types
export const FilesTabComponent = React.memo(() => {
  const supplierId = useSelector(selectSelectedSupplierId);
  const supplier = useSelector(selectSelectedSupplier);

  const [files, setFiles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getFiles, createDefaultFolders } = useFiles();
  const authenticated = useSelector(selectIsAuthenticated);

  async function fetchFiles(): Promise<void> {
    setIsLoading(true);
    try {
      if (authenticated) {
        const myFiles = await getFiles();
        setFiles(myFiles || []);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCreateDefaultFolders(): Promise<void> {
    if (supplier && supplier.name) {
      try {
        startLoading();
        await createDefaultFolders(supplier.name);
        fetchFiles();
      } catch (err) {
        showSnackbar({
          message: 'Folders could not be created due to internal error. Please, contact your System Administrator.',
          type: 'error',
        });
      } finally {
        finishLoading();
      }
    }
  }

  useEffect(() => {
    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, supplierId]);

  if (isLoading) {
    return (
      <div className={cn.progressContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (files.length === 0) {
    return (
      <div>
        <Button onClick={handleCreateDefaultFolders} data-role="create-default-folders">
          Create default folders
        </Button>
      </div>
    );
  }

  return (
    <div data-role="custom-tree-view">
      {files.length > 0 && (
        <CustomTreeView tree={files} />
      )}
    </div>
  );
});

export const FilesTab = withRouter(FilesTabComponent);
