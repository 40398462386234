import { Drawer } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { SrmLeftMenu } from 'components/srm/leftMenu/SrmLeftMenu';
import { selectIsMenuOpened } from 'store/actionBar/Selector';
import cn from './LeftMenuContainer.module.scss';


export class LeftMenuDrawerComponent extends React.PureComponent {
  render() {
    const { isMenuOpen } = this.props;
    return (
      <Drawer
        open={isMenuOpen}
        anchor="left"
        variant="persistent"
        classes={{
          root: cn.drawerRoot,
          paper: cn.drawerPaper,
        }}
      >
        <SrmLeftMenu />
      </Drawer>
    );
  }
}

const mapStateToProps = state => ({
  isMenuOpen: selectIsMenuOpened(state),
});

LeftMenuDrawerComponent.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export const LeftMenuDrawer = connect(mapStateToProps)(LeftMenuDrawerComponent);
