import { Action } from 'redux';

export enum ActionTypes {
  MENU_CLICK = 'MENU_CLICK',
}

export interface ClickMenuAction extends Action<typeof ActionTypes.MENU_CLICK> {
  isOpened: boolean;
}

export type ActionBarAction = ClickMenuAction;

export interface ActionBarState {
  isMenuOpened: boolean;
}
