import React from 'react';
import classNames from 'classnames';
import { ButtonBase } from '@material-ui/core';
import cn from 'components/common/Button/Button.module.scss';

interface CustomizedButtonProps {
  onClick: (event: React.MouseEvent) => void;
  children: React.ReactElement[] | React.ReactElement | string;
  color?: 'primary' | 'secondary';
  className?: string;
  disabled?: boolean;
}

export const Button = React.memo(
  ({
    color = 'secondary', onClick, className, children, disabled
  }: CustomizedButtonProps) => (
    <ButtonBase
      className={classNames(
        cn.addBusinessCaseButtonRoot,
        className,
      )}
      classes={{
        root: cn[color],
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </ButtonBase>
  ),
);
