import { Reducer } from 'redux';
import { MicrosoftActionTypes, MicrosoftGraphAction, MicrosoftGraphState } from './types';

export const initialState: MicrosoftGraphState = {
  isAuthenticated: false,
};

export const MicrosoftGraphReducer: Reducer<MicrosoftGraphState, MicrosoftGraphAction> =
  (state = initialState, action): MicrosoftGraphState => {
    if (action.type === MicrosoftActionTypes.SET_IS_AUTHENTICATED) {
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    }
    return state;
  };
