import { Reducer } from 'redux';
import { LogsAction, LogsActionsTypes, LogsState } from './types';

const initialState: LogsState = [];

export const LogsReducer: Reducer<LogsState, LogsAction> = (state = initialState, action): LogsState => {
  if (action.type === LogsActionsTypes.SET_LOGS) {
    return action.logs;
  }
  return state;
};
