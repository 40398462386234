import React, { ChangeEvent, useState } from 'react';
import { DialogContent, TextField, Button, Popover, CircularProgress } from '@material-ui/core';
import cn from './CreateFolderModal.module.scss';

type CreateFolderModalProps = {
  anchorEl: Element;
  onSubmit: (folderName: string) => void;
  onClose: () => void;
};

export const CreateFolderModal = ({ anchorEl, onSubmit, onClose}: CreateFolderModalProps) => {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    setValue(event.target.value);
    setErrorMessage('');
  }

  async function handleSubmit(): Promise<void> {
    if (!value) {
      setErrorMessage('Please enter folder name.');
      return;
    }
    try {
      setIsLoading(true);
      await onSubmit(value);
    } catch (err) {
      setErrorMessage((err as any).message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
    >
      <DialogContent className={cn.content}>
        <TextField
          label="Folder Name"
          value={value}
          onChange={handleChange}
          variant="outlined"
          error={Boolean(errorMessage)}
          className={cn.textField}
          helperText={errorMessage}
          data-role="name-input"
        />
        <div className={cn.buttonContainer}>
          {isLoading ? (
            <CircularProgress size={36} />
          ) : (
            <Button
              onClick={handleSubmit}
              className={cn.button}
              variant="contained"
              color="primary"
              data-role="submit"
            >
              Submit
            </Button>
          )}
        </div>
      </DialogContent>
    </Popover>
  );
};
