import React from 'react';
import { Dialog, DialogActions, Button, DialogContent } from '@material-ui/core';
import cn from './ConfirmRenameModal.module.scss';

type ConfirmModalProps = {
  open: boolean;
  newFileName: string;
  onSubmit: (file: File) => void;
  onClose: () => void;
  file?: File;
};

export function ConfirmRenameModal({open, file, newFileName, onClose, onSubmit}: ConfirmModalProps) {

  function handleRename(): void {
    if (file) {
      const newFile = new File([file], newFileName);
      onSubmit(newFile);
    }
    onClose();
  }

  function handleReplace(): void {
    if (file) {
      const newFile = new File([file], file.name);
      onSubmit(newFile);
    }
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <div className={cn.content} data-role="content">
          <span>Do you want to rename the new file</span>
          <span>
            <span className={cn.fileName}>{file && file.name}</span> into
            <span className={cn.fileName}> {newFileName}</span>
          </span>
          <span>or replace existing one?</span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRename} data-role="rename">Rename</Button>
        <Button onClick={handleReplace} data-role="replace">Replace</Button>
      </DialogActions>
    </Dialog>
  );
}
