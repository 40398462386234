import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { Publish } from '@material-ui/icons';
import cn from './UploadButton.module.scss';

type UploadButtonProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  id: string | number;
  label?: string;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'secondary' | 'default';
  className?: string;
  disabled?: boolean;
}

export function UploadButton({ onChange, id, label, className, disabled }: UploadButtonProps) {
  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    onChange(event);
  }

  function handleClick(event: any): void {
    event.stopPropagation();
  }

  return (
    <div className={classNames(cn.root, className)}>
      <input
        className={cn.input}
        id={`contained-button-file-${id}`}
        onChange={handleChange}
        onClick={handleClick}
        type="file"
        value={''}
        disabled={disabled}
      />
      {label ? (
        <label htmlFor={`contained-button-file-${id}`} className={cn.uploadButtonWithLabel} onClick={handleClick}>
          <Publish htmlColor="#FFFFFF" onClick={handleClick} className={cn.icon}/>
          <div>{label}</div>
        </label>
      ) : (
        <label htmlFor={`contained-button-file-${id}`} className={cn.uploadButton} onClick={handleClick}>
          <Publish htmlColor="#0072ba" onClick={handleClick} className={cn.icon}/>
        </label>
      )}
    </div>
  );
}
