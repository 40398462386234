import { Reducer } from 'redux';
import { AuthorizationActionTypes, ActionTypes, AuthorizationState } from './types';
import Cookies from 'universal-cookie';
const cookie = new Cookies()

const initialState: AuthorizationState = {
  user: cookie.get('user'),
  errors: null,
  authenticatedMicrosoft: false,
};

const AuthorizationReducer: Reducer = (state = initialState, action: AuthorizationActionTypes): AuthorizationState => {
  if (localStorage.getItem('user') === null && cookie.get('user') !== null) {
    localStorage.setItem('user', JSON.stringify(cookie.get('user')))
  }
  switch (action.type) {
    case ActionTypes.LOGOUT:
      return {
        ...state,
        user: null,
        errors: null,
      };
    case ActionTypes.AUTHENTICATION_MICROSOFT:
      return {
        ...state,
        authenticatedMicrosoft: action.authenticated,
      };
    case ActionTypes.REDIRECT:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};

export default AuthorizationReducer;
