import { Action } from 'redux';

export interface BusinessCaseStatus {
  name: string;
  value: number;
}

export type BusinessCaseStatusList = Array<BusinessCaseStatus>;

export interface TabsState {
  selectedTab: number;
  businessCaseStatusList: BusinessCaseStatusList;
}

export enum ActionTypes {
  SET_SELECTED_TAB = 'SET_SELECTED_TAB',
  SET_BUSINESS_CASE_STATUS_LIST = 'SET_BUSINESS_CASE_STATUS_LIST',
}

export interface SetSelectedTab extends Action<typeof ActionTypes.SET_SELECTED_TAB> {
  tab: number;
}

export interface SetBusinessCaseStatusList extends Action<typeof ActionTypes.SET_BUSINESS_CASE_STATUS_LIST> {
  statusList: BusinessCaseStatusList;
}

export type TabsAction = SetSelectedTab | SetBusinessCaseStatusList;
