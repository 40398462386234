import React, { useState } from 'react';
import classNames from 'classnames';
import { ArrowBackIos } from '@material-ui/icons';
import { SupplierCardDetails } from 'components/SupplierCard/SupplierCardDetails/SupplierCardDetails';
import { ToDoList } from 'components/SupplierCard/ToDoList/ToDoList';
import { SupplierCardTabs } from 'components/SupplierCard/Tabs/SupplierCardTabs';
import { Button } from 'components/common/Button/Button';
import { LogDrawer } from 'components/SupplierCard/Log/LogDrawer/LogDrawer';
import cn from './SupplierCard.module.scss';

type SupplierCardProps = {
  className?: string;
};

export const SupplierCard = React.memo(({ className }: SupplierCardProps) => {
  const [logOpened, setLogOpened] = useState(true);

  function handleLogToggle(open: boolean): void {
    setLogOpened(open);
  }

  return (
    <div className={classNames(className, cn.supplierCard, { [cn.logOpened]: logOpened })}>
      <div className={cn.supplierCardContent}>
        <div className={cn.supplierCardTitle}>
          Supplier Card
        </div>
        <div>
          <SupplierCardDetails />
          <ToDoList />
          <SupplierCardTabs />
        </div>
      </div>
      <div className={cn.logButtonContainer}>
        <Button onClick={(): void => handleLogToggle(true)} className={cn.logButton}>
          <ArrowBackIos className={cn.arrowBackIcon} />
          <span>Log</span>
        </Button>
      </div>
      <LogDrawer logOpened={logOpened} onLogToggle={handleLogToggle} />
    </div>
  );
});
