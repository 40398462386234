import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { CheckOutlined, ClearOutlined } from '@material-ui/icons';
import api from 'api';
import { MyPageTable } from 'components/common/myPageTable/MyPageTable';
import { Button } from 'components/common/Button/Button';
import { selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import { SupplierId } from 'store/suppliersList/types';
import { showSnackbar } from 'hooks/useSnackbar';
import cn from './TrucksTab.module.scss';
import { AppState } from '../../../../store/RootReducer';

type MapStateToProps = {
  selectedSupplierId: SupplierId;
};

type TrucksTabProps = MapStateToProps;

const TrucksTabComponent = React.memo(({ selectedSupplierId }: TrucksTabProps) => {
  const [data, setData] = useState<string>();
  const [opened, setOpened] = useState(false);
  const [editedRow, setEditedRow] = useState<string | null>(null);

  const requestData = useCallback(async () => {
    startLoading();
    try {
      //const Trucks = await api.getTrucks(selectedSupplierId);
      //setData(Trucks || []);
    } catch (err) {
    }
    finishLoading();
  }, [selectedSupplierId]);

  useEffect(() => {
    if (selectedSupplierId) {
      requestData();
    }
  }, [selectedSupplierId, requestData]);

  return (
    <div className={cn.tabContainer}>
     
    </div>
  );
});

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedSupplierId: selectSelectedSupplierId(state),
});

export const TrucksTab = connect(mapStateToProps)(TrucksTabComponent);
