import { useMicrosoftAuth } from 'hooks/useMicrosoftAuth';
import { showSnackbar } from 'hooks/useSnackbar';

export type Attendee = {
  emailAddress: {
    address: string;
    name: string;
  };
  type: 'required' | 'optional' | 'resource' | string;
};

export type Event = {
  subject: string;
  body: { content: string };
  start: { dateTime: Date };
  end: { dateTime: Date };
  isReminderOn: boolean;
  reminderMinutesBeforeStart: number;
  attendees?: Attendee[];
};

type UseCalendar = {
  postEvent: (event: Event) => Promise<void>;
};

export const useCalendar = () => {
  const { getClient } = useMicrosoftAuth();

  async function postEvent(event: Event): Promise<void> {
    const client = await getClient();

    await client
      .api('/me/calendar/events')
      .post({
        ...event,
        body: {
          ...event.body,
          contentType: 'text',
        },
        start: {
          ...event.start,
          timeZone: '',
        },
        end: {
          ...event.end,
          timeZone: '',
        },
      });
    showSnackbar({
      message: 'Meeting was added into the Microsoft Calendar.',
      type: 'success',
    });
  }

  return {
    postEvent,
  };
};
