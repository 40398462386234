import React from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { Log } from 'components/SupplierCard/Log/Log/Log';
import cn from './LogDrawer.module.scss';

type LogDrawerProps = {
  logOpened: boolean;
  onLogToggle: (open: boolean) => void;
}

export const LogDrawer = React.memo(({ logOpened, onLogToggle }: LogDrawerProps) => {
  return (
    <Drawer
      open={logOpened}
      variant="persistent"
      anchor="right"
      classes={{
        root: cn.drawerRoot,
        paper: cn.drawerPaper,
      }}
    >
      <div className={cn.logContainer}>
        <div className={cn.logHeader}>
          <IconButton onClick={(): void => onLogToggle(false)} size="small" className={cn.collapseButton}>
            <ArrowForwardIos />
          </IconButton>
          <div>Log</div>
        </div>
        <Log />
      </div>
    </Drawer>
  )
});
