export function cutUrl(url: string): string {
  try {
    const replaceHttps = /^(https?|http):\/\//;
    let newUrl = url.replace(replaceHttps, '');

    const replaceWww = /^(www?)./;

    newUrl = newUrl.replace(replaceWww, '');
    const urlArray = newUrl.split('.');

    if (urlArray.length > 1) {
      urlArray.pop();
    }
    return urlArray.join('.');
  } catch (err) {
    return url;
  }
}

export function numberWithSpaces(x: string | number): string {
  const str = x.toString();
  if (Number.isNaN(parseInt(str))) {
    return '0';
  }
  if (parseInt(str) !== 0) {
    const formattedValue = x ? str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '0';
    return formattedValue.startsWith('-') ? formattedValue.replace('-', '- ') : formattedValue;
  }
  return str;
}

export function roundToDecimals(number: string | number, fractionDigits: number): string {
  return parseFloat(parseFloat(number.toString()).toFixed(fractionDigits)).toString();
}
