import React, { createContext, Dispatch, useState } from 'react';

import { Progress } from 'components/common/progress/Progress';
import { Disabling } from 'hoc/disabling/Disabling';

type ProgressProviderProps = {
  initialValue: boolean;
  children: any;
}

type UseProgress = {
  ProgressProvider: ({initialValue, children}: ProgressProviderProps) => JSX.Element;
  startLoading: () => void;
  finishLoading: () => void;
}

const ctx = (): UseProgress => {
  const Context = createContext({});
  let updateState: Dispatch<React.SetStateAction<boolean>>;

  /* eslint-disable react/prop-types */
  const ProgressProvider = ({initialValue = false, children}: ProgressProviderProps) => {

    const [loading, setLoading] = useState(initialValue);

    updateState = setLoading;

    return (
      <Context.Provider value={loading}>
        <Progress isLoading={loading}/>
        <Disabling isLoading={loading}>
          {children}
        </Disabling>
      </Context.Provider>
    );
  };

  function startLoading(): void {
    updateState(true);
  }

  function finishLoading(): void {
    updateState(false);
  }

  return {ProgressProvider, startLoading, finishLoading};
};

const {ProgressProvider, startLoading, finishLoading} = ctx();

export { ProgressProvider, startLoading, finishLoading };
