import { Reducer } from 'redux';
import { ActionTypes, TabsAction, TabsState } from './types';

export const initialState: TabsState = {
  selectedTab: 0,
  businessCaseStatusList: [],
};

export const TabsReducer: Reducer<TabsState, TabsAction> =
  (state = initialState, action): TabsState => {
    switch (action.type) {
      case ActionTypes.SET_SELECTED_TAB:
        return { ...state, selectedTab: action.tab };
      case ActionTypes.SET_BUSINESS_CASE_STATUS_LIST:
        return { ...state, businessCaseStatusList: action.statusList };
      default:
        return state;
    }
  };
