import { combineReducers } from 'redux';
import { ResponsibleSalesReducer } from 'store/responsibleSales/Reducer';
import { ActionBarReducer } from 'store/actionBar/Reducer';
import { LogsReducer } from 'store/logs/Reducer';
import { ToDoListReducer } from 'store/toDoList/Reducer';
import { FilesReducer } from 'store/files/Reducer';
import { MicrosoftGraphReducer } from 'store/microsoftGraph/Reducer';
import { SuppliersListReducer } from './suppliersList/Reducer';
import { TabsReducer } from './tabs/Reducer';
import {
  SearchListReducer,
  SearchQueryReducer,
  SearchTypeReducer
} from './search/Reducer';
import AuthorizationReducer from './authorization/Reducer';

export const rootReducer = combineReducers({
  session: AuthorizationReducer,
  actionBar: ActionBarReducer,
  toDoList: ToDoListReducer,
  responsibleSales: ResponsibleSalesReducer,
  tabs: TabsReducer,
  suppliersList: SuppliersListReducer,
  logs: LogsReducer,
  files: FilesReducer,
  microsoftGraph: MicrosoftGraphReducer,
  searchResultsList: SearchListReducer,
  searchQuery: SearchQueryReducer,
  searchType: SearchTypeReducer
});

export type AppState = ReturnType<typeof rootReducer>;
