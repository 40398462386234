import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import enGB from 'date-fns/locale/en-GB';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createTheme from '@material-ui/core/es/styles/createTheme'
import { red } from '@material-ui/core/es/colors';

import ErrorBoundary from 'hoc/ErrorBoundary';
import { Footer } from 'components/common/footer/Footer';
import { SnackbarProvider, showSnackbar } from 'hooks/useSnackbar';
import { ProgressProvider } from 'hooks/useProgress';
import Routes from 'RouteConfig';
import { cleanupInterceptors, setupInterceptors } from 'services/ApiService';
import { logout as logoutAction, removeCookies } from 'store/authorization/ActionCreator';
import cn from './App.module.css';

class App extends React.Component {
  interceptors = [];

    theme = createTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      secondary: red,
    },
  });

  constructor(props) {
    super(props);
    const { logout } = this.props;
    const {
      requestInterceptor,
      responseInterceptor,
    } = setupInterceptors(logout, removeCookies, showSnackbar);

    this.interceptors.push(requestInterceptor, responseInterceptor);
  }

  componentWillUnmount() {
    cleanupInterceptors(this.interceptors);
  }

  render() {
    return (
      <ThemeProvider theme={this.theme}>
        <ProgressProvider>
          <SnackbarProvider>
            <div className={cn.pageWrapper}>
              <ErrorBoundary>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                  <Routes />
                </MuiPickersUtilsProvider>
              </ErrorBoundary>
              <Footer />
            </div>
          </SnackbarProvider>
        </ProgressProvider>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  logout: PropTypes.func,
};

const mapDispatchToProps = {
  logout: logoutAction,
};

export default connect(null, mapDispatchToProps)(App);
