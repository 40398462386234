import api from 'api';
import { SupplierId } from 'store/suppliersList/types';
import { ThunkAction } from '../StoreConfig';
import {
  AddToDoAction, CompleteToDoAction, SetToDoListAction, ToDo, ToDoListActionTypes,
} from './types';

const setToDoList = (list: Array<ToDo>): SetToDoListAction => ({
  type: ToDoListActionTypes.SET_TO_DO_LIST,
  list,
});

const addToDoSuccess = (toDo: ToDo): AddToDoAction => ({
  type: ToDoListActionTypes.ADD_TO_DO,
  toDo,
});

const completeToDoSuccess = (toDo: ToDo): CompleteToDoAction => ({
  type: ToDoListActionTypes.COMPLETE_TO_DO,
  toDo,
});

export const addToDo = (
  supplierId: SupplierId, addedToDo: ToDo,
): ThunkAction<void, AddToDoAction> => async (dispatch): Promise<void> => {
  const { toDo } = await api.addToDo(supplierId, addedToDo);
  dispatch(addToDoSuccess(toDo));
};

export const completeToDo = (
  supplierId: SupplierId, toDo: ToDo,
): ThunkAction<void, CompleteToDoAction> => async (dispatch): Promise<void> => {
  await api.editToDo(supplierId, { ...toDo, done: true });
  dispatch(completeToDoSuccess(toDo));
};

export const getToDoList = (
  selectedSupplierId: SupplierId,
): ThunkAction<void, SetToDoListAction> => async (dispatch): Promise<void> => {
  if (!selectedSupplierId) {
    return;
  }
  const { toDoList } = await api.getToDoList(selectedSupplierId);
  dispatch(setToDoList(toDoList));
};
