import React, { ChangeEvent, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, TextField, Button, DialogTitle,
} from '@material-ui/core';

type SaveToDoModalProps = {
  isOpened: boolean;
  toggle: () => void;
  handleSave: (value: string) => void;
  handleCancel: () => void;
}

export const SaveToDoModal = React.memo(({
  isOpened, toggle, handleSave, handleCancel,
}: SaveToDoModalProps) => {
  const [value, setValue] = useState('');

  return (
    <Dialog
      open={isOpened}
      onClose={toggle}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add Comment</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          rows={5}
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => setValue(event.target.value)}
          fullWidth
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={(): void => handleSave(value)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
