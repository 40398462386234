import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { Hidden } from '@material-ui/core';
import { selectSupplier as selectSupplierAction } from 'store/suppliersList/ActionCreator';
import { selectSelectedSupplier } from 'store/suppliersList/Selector';
import { SupplierCard } from 'components/SupplierCard/SupplierCard';
import { selectIsMenuOpened } from 'store/actionBar/Selector';
import { getSavedSupplier } from '../../../helpers';
import constants from '../../../constants/Routes';
import cn from './SupplierCardPage.module.scss';

export class SrmMainContentContainer extends React.PureComponent {
  componentDidMount() {
    const { handleSupplierSelected, match, history } = this.props;
    const selectedSupplierId = match.params.id;
    if (!selectedSupplierId) {
      const savedSupplier = getSavedSupplier();
      if (savedSupplier) {
        handleSupplierSelected(savedSupplier);
        history.push(`${constants.supplierCard}/${savedSupplier}`);
      }
    } else {
      handleSupplierSelected(selectedSupplierId);
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location !== location) {
      const { handleSupplierSelected, match } = this.props;
      const selectedSupplierId = match.params.id;
      handleSupplierSelected(selectedSupplierId);
    }
  }

  render() {
    const { isMenuOpen, selectedSupplier } = this.props;
    return (
      <div className={cn.root}>
        <Hidden smUp={!selectedSupplier}>
          <SupplierCard
            className={classNames(
              cn.content,
              { [cn.mainContentPaddingMenuOpened]: isMenuOpen },
              { [cn.mainContentPaddingMenuClosed]: !isMenuOpen },
            )}
          />
        </Hidden>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isMenuOpen: selectIsMenuOpened(state),
  selectedSupplier: selectSelectedSupplier(state),
});

const mapDispatchToProps = {
  handleSupplierSelected: selectSupplierAction,
};

SrmMainContentContainer.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  handleSupplierSelected: PropTypes.func,
  selectedSupplier: PropTypes.object,
};

export const SupplierCardPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SrmMainContentContainer),
);
