import { Reducer } from 'redux';
import { FilesActions, FilesActionType, FilesState } from 'store/files/types';

const initialState: FilesState = {};

export const FilesReducer: Reducer<FilesState, FilesActions> =
  (state = initialState, action): FilesState => {
    switch (action.type) {
      case FilesActionType.ADD_FILES:
        return {
          ...state,
          [action.folderId]: state[action.folderId] ? [...state[action.folderId], ...action.files] : action.files,
        };
      case FilesActionType.REMOVE_FILE:
        return {
          ...state,
          [action.folderId]: state[action.folderId].filter(file => file !== action.file),
        };
      default:
        return state;
    }
  };
