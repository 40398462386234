import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import routes from 'constants/Routes';

export const PrivateRoute = ({
  component: Component, isAuth, render, ...rest
}) => {
  let routerRender;

  if (render) {
    routerRender = isAuth ? render : () => (<Redirect to={routes.loginPage} />);
  } else {
    routerRender = isAuth ? props => (<Component {...props} />)
      : () => (<Redirect to={routes.loginPage} />);
  }

  return (
    <Route
      {...rest}
      render={routerRender}
    />
  );
};

const mapStateToProps = state => ({
  isAuth: !!state.session.user,
});

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.shape(),
  ]),
  isAuth: PropTypes.bool,
  render: PropTypes.func,
};

const ConnectedPrivateRoute = connect(mapStateToProps)(PrivateRoute);
export default ConnectedPrivateRoute;
