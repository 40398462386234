import { Reducer } from 'redux';
import {
    ActionTypes,
    SearchListState,
    SetSearchResultsListAction,
    SearchQueryState,
    SetSearchTermAction,
    SearchType,
    SearchTypeState,
    SearchTypeAction
} from './types';


export const listInitialState: SearchListState = {
    searchList: [],
    pageNumber: 1
}

export const queryInitialState: SearchQueryState = {
    query: ''
}

export const searchTypeInitialState: SearchTypeState = {
    crmIsActive: true,
    srmIsActive: true,
    claimsIsActive: true
}

export const SearchListReducer: Reducer<SearchListState, SetSearchResultsListAction> = (
    state = listInitialState, action
): SearchListState => {
    switch (action.type) {
        case ActionTypes.SET_SEARCH_RESULT_LIST:
            return { ...state, searchList: action.searchList };
        default:
            return state;
    }
};

export const SearchQueryReducer: Reducer<SearchQueryState, SetSearchTermAction> = (
    state = queryInitialState, action
): SearchQueryState => {
    switch (action.type) {
        case ActionTypes.SEARCH:
            return {
                ...state,
                query: action.query
            };
        default:
            return state;
    }
};

export const SearchTypeReducer: Reducer<SearchTypeState, SearchTypeAction> = (
    state = searchTypeInitialState, action
): SearchTypeState => {
    switch (action.type) {
        case ActionTypes.SET_ACTIVITY_CRM:
            return {
                ...state,
                crmIsActive: !action.crmActive
            };
        case ActionTypes.SET_ACTIVITY_SRM:
            return {
                ...state,
                srmIsActive: !action.srmActive
            };
        case ActionTypes.SET_ACTIVITY_CLAIMS:
            return {
                ...state,
                claimsIsActive: !action.claimsActive
            }
        default:
            return state;
    }
}
