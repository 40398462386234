import React from 'react';
import { Dialog, DialogActions, Button, DialogContent } from '@material-ui/core';
import { DriveItem } from 'hooks/useFiles';
import cn from './ConfirmDeleteModal.module.scss';

type ConfirmModalProps = {
  open: boolean;
  item: DriveItem;
  onSubmit: (item: DriveItem) => void;
  onClose: () => void;
};

export function ConfirmDeleteModal({open, item, onClose, onSubmit}: ConfirmModalProps) {

  async function handleDelete(item: DriveItem): Promise<void> {
    await onSubmit(item);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <div className={cn.content} data-role="content">
          <span>Do you want to delete <span className={cn.fileName}>{item.name} ?</span></span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): Promise<void> => handleDelete(item)}
          data-role="submit"
        >
          Delete
        </Button>
        <Button
          onClick={onClose}
          data-role="cancel"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
