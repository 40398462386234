import { Action } from 'redux';
import { User } from 'types/User';

export enum ActionTypes {
  LOGOUT = 'LOGOUT',
  AUTHENTICATION_MICROSOFT = 'AUTHENTICATION_MICROSOFT',
  REDIRECT = 'REDIRECT',
}

export type LogoutAction = Action<typeof ActionTypes.LOGOUT>;

export type RedirectAction = Action<typeof ActionTypes.REDIRECT> & {
  user: User;
}

export type SetAuthenticationMicrosoft = Action<typeof ActionTypes.AUTHENTICATION_MICROSOFT> & {
  authenticated: boolean;
}

export type AuthorizationActionTypes = LogoutAction | SetAuthenticationMicrosoft | RedirectAction;

export interface AuthorizationState {
  user: User | null;
  errors: string | null;
  authenticatedMicrosoft: boolean;
}




