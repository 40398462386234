import { ActionTypes, LogoutAction, RedirectAction, SetAuthenticationMicrosoft } from './types';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const logout = (callback?: () => {}): LogoutAction => {
  localStorage.removeItem('user');
  if (callback) {
    callback();
  }
  return {
    type: ActionTypes.LOGOUT,
  };
};

export const removeCookies = () => {
  cookie.remove('user', { domain: process.env.REACT_APP_DOMAIN });
  if (cookie.get('user') !== undefined)
    cookie.set('user', cookie.get('user'), { domain: process.env.REACT_APP_DOMAIN, expires: new Date() ,path:'/'})
}

export const redirect = (user: any): RedirectAction => ({
  type: ActionTypes.REDIRECT,
  user,
});

export const setAuthenticationMicrosoft = (authenticated: boolean): SetAuthenticationMicrosoft => ({
  type: ActionTypes.AUTHENTICATION_MICROSOFT,
  authenticated,
});


