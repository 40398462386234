import { useState } from 'react';
import { setHours, setMinutes } from 'date-fns';

type InitialData = {
  enable?: boolean;
  startTime?: Date;
  endTime?: Date;
  notificationTime?: number;
}

export type UseNotification = {
  enable: boolean;
  startTime: Date;
  endTime: Date;
  notificationTime: number;
  onEnableChange: (enable: boolean) => void;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  onNotificationTimeChange: (time: number) => void;
};

export const useNotification = ({
  startTime: initialStartTime = setMinutes(setHours(new Date(), 9), 0),
  endTime: initialEndTime= setMinutes(setHours(new Date(), 9), 30),
  notificationTime: initialNotificationTime = 15,
  enable: initialEnable= true,
}: InitialData): UseNotification => {

  const [enable, setEnable] = useState(initialEnable);
  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);
  const [notificationTime, setNotificationTime] = useState(initialNotificationTime);

  return {
    enable,
    startTime,
    endTime,
    notificationTime,
    onEnableChange: setEnable,
    onStartDateChange: setStartTime,
    onEndDateChange: setEndTime,
    onNotificationTimeChange: setNotificationTime,
  }
};
