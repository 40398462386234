import { Reducer } from 'redux';
import { ActionTypes, Supplier, SuppliersListAction, SuppliersListState } from './types';

export const initialState: SuppliersListState = {
    supplierList: [],
    selectedSupplierId: '',
    selectedSortColumnId: 'name',
    selectedSortAsc: true
};

function comparer(state: SuppliersListState, a: any, b: any) {
    const sortDir = state.selectedSortAsc ? 1 : -1;
    const columnId = state.selectedSortColumnId as keyof Supplier;
    return sortDir * (a[columnId] === b[columnId] ? 0 : (a[columnId] > b[columnId] ? 1 : -1));
  }

export const SuppliersListReducer: Reducer<SuppliersListState, SuppliersListAction> = (
    state = initialState, action
): SuppliersListState => {
    switch (action.type) {
        case ActionTypes.ADD_SUPPLIER:
            return {
                ...state,
                supplierList: [...state.supplierList, action.supplier].sort((a, b) => {
                    return comparer(state, a, b);
                }),
            };
        case ActionTypes.EDIT_SUPPLIER:
            return {
                ...state,
                supplierList: state.supplierList.map(supplier => {
                    if (supplier.id === action.supplier.id) {
                        return {
                            ...action.supplier,
                        }
                    }
                    return supplier;
                }).sort((a, b) => {
                    return comparer(state, a, b);
                }),
            };
        case ActionTypes.SELECT_SUPPLIER:
            return { ...state, selectedSupplierId: action.id };
        case ActionTypes.SET_SUPPLIERS_LIST:
            return { ...state, supplierList: action.suppliersList };
        case ActionTypes.TOGGLE_ACTIVITY: {
            return {
                ...state,
                supplierList: state.supplierList.map(supplier => (supplier.id === state.selectedSupplierId
                    ? { ...supplier, isActive: action.active }
                    : supplier)),
            };
        }
        default:
            return state;
    }
};
