import React from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { SrmLayoutRoute } from 'layouts/SrmLayoutRoute';
import ConnectedPrivateRoute from 'hoc/PrivateRoute';
import constants from 'constants/Routes';
import NotFound from 'components/common/notFound/NotFound';
import { SupplierCardPage } from 'containers/srm/supplierCardPage/SupplierCardPage';
import { MyPage } from 'containers/MyPage/MyPage';
import { SearchPage } from 'components/common/SearchPage/SearchPage';
import { ActionBar } from 'components/common/ActionBar/ActionBar';
import { SearchPageActionBar } from 'components/common/SearchPageActionBar/SearchPageActionBar';
import { logout as logoutAction, redirect as redirectAction } from 'store/authorization/ActionCreator';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

function getAuthString() {
  return `token=${JSON.parse(
    localStorage.getItem('user'),
  ).token}&userName=${JSON.parse(localStorage.getItem('user')).name}`;
}

const RedirectComponent = () => {
  const dispatch = useDispatch();
  const match = useParams();
  const user = {
    token: match.token,
    name: match.userName,
  };
  localStorage.setItem('user', JSON.stringify(cookie.get('user')))
  localStorage.setItem('supplier', match.id);
  dispatch(redirectAction(user));

  return (<Redirect exact to={constants.supplierCard} />)
}

const Routes = () => (
  <Switch>
    <Redirect exact from={constants.root} to={constants.supplierCard} />
    <Route
      path={constants.loginPage}
      component={() => {
        window.location.href = constants.loginPageUrl;
        return null;
      }}
    />
    <Route path={`/redirect/:id?/:token?/:userName?`} component={RedirectComponent} />
    <SrmLayoutRoute exact path={`${constants.supplierCard}/:id?`} component={SupplierCardPage} toolbar={ActionBar} />
    <SrmLayoutRoute exact path={constants.myPage} component={MyPage} />
    <SrmLayoutRoute exact path={constants.searchResult} component={SearchPage} toolbar={SearchPageActionBar}/>
    <ConnectedPrivateRoute
      path={constants.planingDeskLandingPage}
      component={() => {
        var token = getAuthString();
        logoutAction();
        window.location.href = `${constants.planningDeskLandingPageUrl}?${token}`;
        return null;
      }}
    />
    <ConnectedPrivateRoute
      path={constants.terminalPage}
      component={() => {
        var token = getAuthString();
        logoutAction();
        window.location.href = `${constants.terminalPageUrl}?${token}`;
        return null;
      }}
    />

    <ConnectedPrivateRoute
      path={constants.claimsPage}
      component={() => {
        var token = getAuthString();
        logoutAction();
        window.location.href = `${constants.claimsPageUrl}?${token}`;
        return null;
      }}
    />
    <ConnectedPrivateRoute
      path={constants.crmPage}
      component={() => {
        var token = getAuthString();
        logoutAction();
        window.location.href = `${constants.crmPageUrl}?${token}`;
        return null;
      }}
    />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
