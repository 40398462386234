const constants = {
  root: '/',
  customerCard: '/customer-card',
  supplierCard: '/supplier-card',
  claimCard: '/claim-card',
  loginPage: '/login',
  myPage: '/my-page',
  searchResult: '/search-page',
  loginPageUrl: process.env.REACT_APP_LOGIN_APP_URL,
  planingDeskLandingPage: '/planning-desk',
  planningDeskLandingPageUrl: `${process.env.REACT_APP_PLANNING_DESK_APP_URL}`,
  terminalPage: '/terminal',
  terminalPageUrl: `${process.env.REACT_APP_TERMINAL_APP_URL}/terminal`,
  claimsPage: '/claims',
  claimsPageUrl: `${process.env.REACT_APP_CLAIMS_APP_URL}`,  
  crmPage: '/crm',  
  crmPageUrl: process.env.REACT_APP_CRM_APP_URL,
  microsoftLoginPage: '/microsoft-graph-login',
};

export default constants;
