import React, { ChangeEvent } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import cn from '../SupplierCardDetails.module.scss';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CalendarTodayOutlined } from '@material-ui/icons';
import { UploadButton } from 'components/SupplierCard/Tabs/FilesTab/UploadButton/UploadButton';
import { DeleteButton } from 'components/SupplierCard/Tabs/FilesTab/DeleteButton/DeleteButton';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'store/microsoftGraph/Selector';

const OtherCertificatesComponent = React.memo(({
    keyValue,
    otherCertificatesItem,
    setOtherCertificatesItemChild,
    handleOtherCertificatesChange,
    handleOtherCertificatesDelete
}: any) => {
    const authenticated = useSelector(selectIsAuthenticated);
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setOtherCertificatesItemChild(value, otherCertificatesItem.validUntil, keyValue);
    };

    function handleDateChange(date: MaterialUiPickersDate): void {
        setOtherCertificatesItemChild(otherCertificatesItem.filename, date, keyValue);
    }

    const handleOtherCertificatesChangeInternal = (event: any, keyValue:any) => {
        handleOtherCertificatesChange(event, keyValue, otherCertificatesItem.validUntil);
    }

    return (
        <TableRow classes={{ root: cn.tableBodyRow }} key={keyValue} >
            <TableCell classes={{ head: cn.tableBodyCell }} padding="none">
                <div className={cn.tableDateCell}>
                    <DatePicker
                        value={otherCertificatesItem.validUntil || null}
                        format="yyyy-MM-dd"
                        onChange={handleDateChange}
                        animateYearScrolling={false}
                        className={otherCertificatesItem?.isDateError !== true ? cn.tableBodyDatePicker : cn.tableBodyDatePicker + " " + cn.error}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <div className={cn.calendarAdornment}>
                                    <CalendarTodayOutlined className={cn.calendarIcon} />
                                </div>
                            ),
                        }}
                        disabled={!authenticated}
                    />
                </div>
            </TableCell>
            <TableCell
                classes={{ head: cn.tableBodySecondCell }}
                padding="none"
            >
                <input
                    type="string"
                    className={otherCertificatesItem?.isFileError !== true ? cn.tableBodyInput : cn.tableBodyInput + " " + cn.error}
                    value={otherCertificatesItem.filename || ""}
                    onChange={handleNameChange}
                    disabled={!authenticated}
                    readOnly={true}
                />
            </TableCell>
            <TableCell
                classes={{ head: cn.tableBodySecondCell }}
                padding="none"
            >
                <UploadButton
                    onChange={(e) => handleOtherCertificatesChangeInternal(e, keyValue)}
                    id={`uploadButton${keyValue}`}
                    className={cn.icon}
                    disabled={!authenticated}
                />
            </TableCell>
            <TableCell
                classes={{ head: cn.tableBodySecondCell }}
                padding="none"
            >
                <DeleteButton
                    onClick={(e) => handleOtherCertificatesDelete(e, keyValue)}
                    id={`deleteButton${keyValue}`}
                    className={cn.icon}
                    disabled={!authenticated}
                />
            </TableCell>
        </TableRow>
    );
});

export const OtherCertificateRow = (OtherCertificatesComponent);