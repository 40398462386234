export enum MicrosoftActionTypes {
  SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED',
}

export interface SetIsAuthenticated {
  type: typeof MicrosoftActionTypes.SET_IS_AUTHENTICATED;
  isAuthenticated: boolean;
}

export type MicrosoftGraphAction = SetIsAuthenticated;

export type MicrosoftGraphState = {
  isAuthenticated: boolean;
}
