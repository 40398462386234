import React, { ComponentType, ReactElement } from 'react';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { emphasize, makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import Select, {
  ControlProps, MenuListComponentProps,
  MultiValueProps,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select';
// eslint-disable-next-line import/namespace,import/named
import { Option } from 'react-select/src/filters';
// eslint-disable-next-line import/named,import/namespace
import { SelectComponents } from 'react-select/src/components';
// eslint-disable-next-line import/namespace,import/named
import { NoticeProps } from 'react-select/src/components/Menu';
import { VirtualizedMenuList } from 'components/common/VirtualizedSelect/VirtualizedMenuList';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
    minWidth: 290,
  },
  input: {
    display: 'flex',
    padding: '10px',
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    paddingLeft: 4,
    fontSize: 16,
  },
  placeholder: {
    paddingLeft: 4,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  label: {
    zIndex: 0,
  },
}));

const NoOptionsMessage: ComponentType<NoticeProps<Option, false>> = ({ selectProps, innerProps, children }: NoticeProps<Option, false>) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.noOptionsMessage}
    {...innerProps}
  >
    {children}
  </Typography>
);

function inputComponent({ inputRef, ...props }:
{ inputRef: () => void | { current: any } }): ReactElement {
  return <div ref={inputRef} {...props} />;
}

const Control: ComponentType<ControlProps<Option, false>> = ({
  children,
  innerProps,
  innerRef,
  selectProps: { classes, TextFieldProps },
  isDisabled,
}: ControlProps<Option, false>) => (
  <TextField
    disabled={isDisabled}
    fullWidth
    variant="outlined"
    InputProps={{
      startAdornment: TextFieldProps.startAdornment,
      inputComponent,
      inputProps: {
        className: classes.input,
        ref: innerRef,
        children,
        ...innerProps,
      },
    }}
    {...TextFieldProps}
  />
);

const SingleValue: ComponentType<SingleValueProps<Option>> = ({ selectProps, innerProps, children }: SingleValueProps<Option>) => (
  <Typography className={selectProps.classes.singleValue} {...innerProps}>
    {children}
  </Typography>
);

const ValueContainer: ComponentType<ValueContainerProps<Option, false>> = ({ selectProps, children }: ValueContainerProps<Option, false>) => (<div className={selectProps.classes.valueContainer}>{children}</div>);

const MultiValue: ComponentType<MultiValueProps<Option>> = ({
  children, selectProps, isFocused, removeProps,
}: MultiValueProps<Option>) => (
  <Chip
    size="small"
    tabIndex={-1}
    label={children}
    className={classNames(selectProps.classes.chip, {
      [selectProps.classes.chipFocused]: isFocused,
    })}
    onDelete={removeProps.onClick}
    deleteIcon={<CancelIcon {...removeProps} />}
  />
);

const Placeholder: ComponentType<PlaceholderProps<Option, false>> = ({ selectProps, innerProps = {} as { style: React.CSSProperties }, children }: PlaceholderProps<Option, false>) => (
  <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
    {children}
  </Typography>
);

const components: Partial<SelectComponents<Option, false>> = {
  Control,
    MenuList: VirtualizedMenuList as ComponentType<MenuListComponentProps<Option, false>>,
  MultiValue,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
};

interface MaterialVirtualizedSelectProps {
  value: { value: any; label: string; data?: any } | Array< { value: any; label: string; data?: any }> | null;
  options: Array<{ value: any; label: string; data?: any }>;
  onChange: (value: any) => void;
  label?: string;
  error?: boolean;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
  name?: string;
  isMulti?: boolean;
  menuIsOpen?: boolean;
  filterOption?: (option: Option, inputValue: string) => boolean;
  startAdornment?: any;
}

export const MaterialVirtualizedSelect = React.memo(({
  value, options, onChange, error, className, label, startAdornment, ...props
}: MaterialVirtualizedSelectProps) => {
  const classes = useStyles();
  return (
    <Select
      isClearable
      classes={classes}
      className={className}
      value={value as Option}
      components={components}
      TextFieldProps={{
        label,
        error,
        startAdornment,
        InputLabelProps: {
          shrink: true,
          disabled: true,
          classes: {
            root: classes.label,
          },
        },
      }}
      onChange={onChange}
      options={options as Array<Option>}
      styles={{
        control: (provided) => ({
          ...provided,
          paddingTop: '9px',
          paddingBottom: '9px',
        }),
        option: (provided) => ({
          ...provided,
          cursor: 'pointer',
          whiteSpace: 'nowrap',
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 101,
        }),
      }}
      {...props}
    />
  );
});
