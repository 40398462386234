import { Reducer } from 'redux';
import { ToDoListAction, ToDoListActionTypes, ToDoListState } from './types';

const initialState: ToDoListState = [];

export const ToDoListReducer: Reducer<ToDoListState, ToDoListAction> = (state = initialState, action) => {
  switch (action.type) {
    case ToDoListActionTypes.SET_TO_DO_LIST:
      return action.list;
    case ToDoListActionTypes.ADD_TO_DO:
      return [...state, action.toDo].sort((first, second) => {
        if (first.date === second.date) {
          return 0;
        }
        return first.date > second.date ? 1 : -1;
      });
    case ToDoListActionTypes.COMPLETE_TO_DO:
      return state.filter(toDo => toDo !== action.toDo);
    default:
      return state;
  }
};
