import { Selector } from '../StoreConfig';
import { BusinessCaseStatusList } from './types';

export const selectBusinessCaseStatusList: Selector<BusinessCaseStatusList> = (state) => {
  return state.tabs.businessCaseStatusList;
};

export const selectSelectedTab: Selector<number> = (state) => {
  return state.tabs.selectedTab;
};
