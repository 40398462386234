import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { TreeView } from '@material-ui/lab';
import { CustomizedTreeItem } from 'components/SupplierCard/Tabs/FilesTab/CustomizedTreeItem/CustomizedTreeItem';
import { DriveItem } from 'hooks/useFiles';

type CustomTreeViewProps = {
  tree: DriveItem[];
}

export function CustomTreeView({ tree }: CustomTreeViewProps): ReactElement {
  const [expanded, setExpanded] = useState<string[]>([]);

  function handleChange(event: ChangeEvent<{}>, nodeIds: string[]): void {
    setExpanded(nodeIds);
  }

  function expandLeaf(id: string): void {
    setExpanded([...expanded, id]);
  }

  useEffect(() => {
    if (tree.length === 1 && tree[0].folder) {
      setExpanded([tree[0].id.toString()])
    }
  }, [tree]);

  return (
     <TreeView
      expanded={expanded}
      onNodeToggle={handleChange}
     >
       {tree.map((leaf, index) => (
         <CustomizedTreeItem
           key={index}
           item={leaf}
           expandLeaf={expandLeaf}
           isRoot
         />
       ))}
     </TreeView>
  )
}


