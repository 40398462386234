import { Reducer } from 'redux';
import { ActionBarAction, ActionBarState, ActionTypes } from './types';

const initialState: ActionBarState = {
  isMenuOpened: true,
};

export const ActionBarReducer: Reducer<ActionBarState, ActionBarAction> =
  (state = initialState, action): ActionBarState => {
    if (action.type === ActionTypes.MENU_CLICK) {
      return { ...state, isMenuOpened: action.isOpened };
    }
    return state;
  };
