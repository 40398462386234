import { Action } from 'redux';

export enum ActionTypes {
    SET_SEARCH_RESULT_LIST = 'SET_SEARCH_RESULT_LIST',
    SEARCH = 'SEARCH',
    SET_ACTIVITY_CRM = 'SET_ACTIVITY_CRM',
    SET_ACTIVITY_SRM = 'SET_ACTIVITY_SRM',
    SET_ACTIVITY_CLAIMS = 'SET_ACTIVITY_CLAIMS'
}

export enum SearchType {
    CLAIMS = 1 << 0,
    CRM = 1 << 1,
    SRM = 1 << 2,
    ALL = ~(~0 << 3)
}

export interface SetSearchResultsListAction extends Action<typeof ActionTypes.SET_SEARCH_RESULT_LIST> {
    searchList: SearchResultList;
    pageNumber: number;
}

export interface SetSearchTermAction extends Action<typeof ActionTypes.SEARCH> {
    query: string;
}

export interface SetCrmActiveAction extends Action<typeof ActionTypes.SET_ACTIVITY_CRM> {
    crmActive: boolean;
}

export interface SetSrmActiveAction extends Action<typeof ActionTypes.SET_ACTIVITY_SRM> {
    srmActive: boolean;
}

export interface SetClaimsActiveAction extends Action<typeof ActionTypes.SET_ACTIVITY_CLAIMS> {
    claimsActive: boolean;
}

export interface SearchListState {
    searchList: SearchResultList;
    pageNumber: number;
}

export interface SearchResult {
    url: string;
    urlText: string;
    descriptionUnsafeHtml: string;
    entityId: string;
}

export type SearchResultList = Array<SearchResult>;


export interface SearchTerm {
    term: string;
    searchType: SearchType;
};

export interface SearchQueryState {
    query: string;
}

export interface SearchTypeState {
    crmIsActive: boolean;
    srmIsActive: boolean;
    claimsIsActive: boolean;
}

export type SearchTypeAction = SetCrmActiveAction | SetSrmActiveAction | SetClaimsActiveAction;