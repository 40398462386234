import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Drawer, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import cn from './MenuAppBarDrawer.module.scss';
import constants from 'constants/MenuApps';
import routeConstants from 'constants/Routes';
import crmImage from 'img/app_crm_v2.svg';
import claimImage from 'img/app-claims.png';
import myBegomaImage from 'img/app-my-begoma.png';
import planningDeskImage from 'img/app_planning_v3.svg';
import srmImage from 'img/app_srm.svg';
import terminalImage from 'img/app-terminal.png';
import menuAppsBottomLogoImage from 'img/Begoma_logo_login.png';

const menuAppsItems = [
  {
    name: constants.planningDesk,
    route: routeConstants.planingDeskLandingPage,
    icon: <img src={planningDeskImage} alt={constants.planningDesk} className={cn.planningDeskIcon} />,
  },
  {
    name: constants.crm,
    route: routeConstants.crmPage,
    icon: <img src={crmImage} alt={constants.crm} className={cn.crmIcon} />,
  },
  {
    name: constants.claims,
    route: routeConstants.claimsPage,
    icon: <img src={claimImage} alt={constants.claims} className={cn.claimIcon} />,
  },
  {
    name: constants.terminalHandling,
    route: routeConstants.terminalPage,
    icon: <img src={terminalImage} alt={constants.terminalHandling} />,
    disabled: true,
  },
  {
    name: constants.srm,
    route: routeConstants.supplierCard,
    icon: <img src={srmImage} alt={constants.srm} className={cn.srmIcon} />
  },
  {
    name: constants.myBegoma,
    route: routeConstants.root,
    icon: <img src={myBegomaImage} alt={constants.myBegoma} />,
    disabled: true,
  },
];

type MenuAppBarDrawerProps = {
  isMenuOpen: boolean;
  handleMenuToggle: () => void;
}

export const MenuAppBarDrawer = React.memo(({ isMenuOpen, handleMenuToggle }: MenuAppBarDrawerProps) => (
  <Drawer
    variant="persistent"
    anchor="left"
    open={isMenuOpen}
    className={cn.drawer}
  >
    <div className={cn.drawerButton} onClick={handleMenuToggle} role="presentation">
      <Close />
    </div>
    <div className={cn.menuAppsSectionTable}>
      <Grid container>
        {menuAppsItems.map(appItem => (
          <Grid
            item xs={6}
            key={appItem.name}
            className={classNames(cn.menuAppsSectionTableCell, {[cn.disabled]: appItem.disabled})}
          >
            {appItem.disabled ? (
              <div>
                <div id={appItem.name} className={cn.menuAppsSectionLogo}>
                  {appItem.icon}
                </div>
                <button type="button" className={cn.menuAppsSectionButton} disabled>
                  {appItem.name}
                </button>
              </div>
            ) : (
              <Link
                id={`${appItem.name}_MenuAppsSelectedButton`}
                to={appItem.route}
                onClick={handleMenuToggle}
              >
                <div id={appItem.name} className={cn.menuAppsSectionLogo}>
                  {appItem.icon}
                </div>
                <button type="button" className={cn.menuAppsSectionButton}>
                  {appItem.name}
                </button>
              </Link>
            )}
          </Grid>
        ))
        }
      </Grid>
    </div>
    <div className={cn.menuAppsBottomLogo}>
      <img src={menuAppsBottomLogoImage} alt="BottomLogo" />
    </div>
  </Drawer>
));

