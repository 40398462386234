import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import MergeIcon from '@material-ui/icons/MergeType';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MuiButton from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/WarningRounded';
import Tooltip from '@material-ui/core/Tooltip';

import { Button } from 'components/common/Button/Button';
import { MaterialVirtualizedSelect } from 'components/common/VirtualizedSelect/MaterialVirtualizedSelect';

import { Supplier } from 'store/suppliersList/types';
import { AppState } from 'store/RootReducer';
import { selectSuppliersList, selectSelectedSupplier } from 'store/suppliersList/Selector';
import { Option } from 'components/common/VirtualizedSelect/ReactSelect';
import clientRoutes from 'constants/Routes';
import api from 'api';
import { finishLoading, startLoading } from 'hooks/useProgress';
import cn from 'components/MergeSuppliers/styles.module.scss';
import { getSuppliersList } from 'store/suppliersList/ActionCreator';
import { showSnackbar } from 'hooks/useSnackbar';

type MapStateToProps = {
  suppliers: Supplier[];
  selectedSupplier?: Supplier;
};

type OwnProps = {
  className?: string;
};

type MergeForm = {
  cardFrom?: Option;
  cardTo?: Option;
};

const mapStateToProps = (state: AppState): MapStateToProps => ({
  suppliers: selectSuppliersList(state),
  selectedSupplier: selectSelectedSupplier(state),
});

const mapDispatchToProps = {
  getSuppliers: getSuppliersList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type MergeSuppliers = OwnProps & ConnectedProps<typeof connector> & RouteComponentProps;

export const MergeSuppliersComponent = React.memo(({
  suppliers, selectedSupplier, history, className,
}: MergeSuppliers) => {
  const initialForm = selectedSupplier ? {
    cardFrom: {
      value: selectedSupplier.id,
      label: selectedSupplier.name,
    },
  } : null;

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const [form, setForm] = useState<MergeForm | null>(initialForm);

  const options = useMemo(
    () => suppliers.map((supplier) => ({ label: supplier.name, value: supplier.id })), [suppliers],
  );

  const filterCardFromOptions = useCallback(
    (option: Option, inputValue: string): boolean => (
      !form || !form.cardTo || form.cardTo.value !== option.value
    ) && option.label.toLowerCase().includes(inputValue.toLowerCase()), [options, form],
  );

  const filterCardToOptions = useCallback(
    (option: Option, inputValue: string): boolean => (
      !form || !form.cardFrom || form.cardFrom.value !== option.value
    ) && option.label.toLowerCase().includes(inputValue.toLowerCase()), [options, form],
  );

  const handleFromChange = (event: any): void => {
    setForm({
      ...form,
      cardFrom: event as Option,
    });
  };

  const handleToChange = (event: any): void => {
    setForm({
      ...form,
      cardTo: event,
    });
  };

  const handleCancel = (): void => {
    setOpen(false);
    setForm(initialForm);
  };

  const handleSubmit = async (): Promise<void> => {
    startLoading();
    try {
      if (!form?.cardFrom || !form.cardTo) {
        setError(true);
        return;
      }
      await api.mergeCards(form.cardFrom.value, form.cardTo.value);
      showSnackbar({
        message: 'Merge of the supplier cards was added into the queue. You will receive an email notification after the merge is completed. Please reload the page to continue working with the updated supplier card.',
        type: 'success',
        autoHideDuration: 10000,
      });
      history.push(`${clientRoutes.supplierCard}/${form.cardTo.value}`);
      setOpen(false);
    } catch (err) {
    } finally {
      finishLoading();
    }
  };

  useEffect(() => {
    setForm(selectedSupplier ? {
      cardFrom: {
        value: selectedSupplier.id,
        label: selectedSupplier.name,
      },
    } : null);
  }, [selectedSupplier]);

  useEffect(() => {
    setError(false);
  }, [form]);

  return (
    <>
      <Button onClick={() => setOpen(true)} color="primary" className={className}>
        <div className={cn.buttonText}>
          <MergeIcon htmlColor="#ffcc00" />
          Merge suppliers
        </div>
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        classes={{
          container: cn.container,
          paper: cn.paper,
        }}
      >
        <DialogTitle>Merge supplier cards</DialogTitle>
        <DialogContent className={cn.content}>
          <div className={cn.warning}>
            <WarningIcon htmlColor="#ff9800" />
            Please update Traffic Info and SOP data manually before merging, otherwise it will be deleted!
          </div>
          <div className={cn.fromSelector}>
            <MaterialVirtualizedSelect
              label="From"
              value={form?.cardFrom || null}
              options={options}
              filterOption={filterCardFromOptions}
              onChange={handleFromChange}
              className={cn.item}
              startAdornment={(
                <Tooltip
                  title="This Supplier Card will be removed after merge."
                  classes={{
                    popper: cn.tooltipPopper,
                    tooltip: cn.tooltip,
                  }}
                >
                  <WarningIcon htmlColor="#f44336" />
                </Tooltip>
              )}
            />
          </div>
          <MaterialVirtualizedSelect
            label="To"
            value={form?.cardTo || null}
            options={options}
            filterOption={filterCardToOptions}
            onChange={handleToChange}
            className={cn.item}
          />
          {error && (
            <div className={cn.error}>
              Please select cards to be merged.
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleSubmit} color="primary">
            Merge
          </MuiButton>
          <MuiButton onClick={handleCancel} color="primary">
            Cancel
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
});

export const MergeSuppliers = withRouter(connector(MergeSuppliersComponent));
