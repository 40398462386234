import React from "react";
import cn from "./SearchPage.module.scss";
import { useSelector } from "react-redux";
import { selectSearchResultList } from "store/search/Selector";
import { Link, useLocation } from "react-router-dom";
import routeConstants from 'constants/Routes';

export const SearchPage = React.memo(() => {
  const location = useLocation();
  const searchResult = useSelector(selectSearchResultList);
  var lastSearchTerm = new URLSearchParams(location.search).get('query');

  function isExternal(url: string) {
    let checkUrl = new URL(url); 
    return checkUrl.protocol + checkUrl.host 
      !== window.location.protocol + window.location.host;
  }

  function renderLink(url: string, urlText: string, entityId: string) {
    if (isExternal(url)) {
      return (
        <a href={url}>{urlText}</a>
      )
    } else {
      return (
        <Link to={`${routeConstants.supplierCard}/${entityId}`}>
          {urlText}
        </Link>
      )
    }
  }

  function renderSearchResult() {
    if (searchResult.length === 0 || lastSearchTerm === "") {
      return (
        <div>
          <h3>
            Nothing was found
            </h3>
        </div>
      )
    } else {
      return (
        searchResult.map((res, index) =>
          <div key={index}>
            <h3>
              {renderLink(res.url, res.urlText, res.entityId)}
            </h3>
            <p
              dangerouslySetInnerHTML={{ __html: res.descriptionUnsafeHtml }}>{ }</p>
            <hr />
          </div>
        )
      )
    }
  }

  return (
    <div className={cn.root}>
      <h2>Search Result for "{lastSearchTerm}"</h2>
      {renderSearchResult()}
    </div>
  );
});