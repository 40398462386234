import React, { useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  format, setHours, setMinutes, getHours, getMinutes,
} from 'date-fns';
import {
  MentionsInput, Mention, OnChangeHandlerFunc, SuggestionDataItem,
} from 'react-mentions';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { CalendarTodayOutlined } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { selectResponsibleSales } from 'store/responsibleSales/Selector';
import { AppState } from 'store/RootReducer';
import { ResponsibleSalesList } from 'store/responsibleSales/types';
import { addToDo as addToDoAction } from 'store/toDoList/ActionCreator';
import { SupplierId } from 'store/suppliersList/types';
import { ToDo } from 'store/toDoList/types';
import { selectSelectedSupplier, selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { selectUserName } from 'store/authorization/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import DateService from 'services/DateService';
import { Notification } from 'components/SupplierCard/ToDoList/NotificationForm/Notification';
import { useNotification } from 'hooks/useNotification';
import { useCalendar, Event, Attendee } from 'hooks/useCalendar';
import { showSnackbar } from 'hooks/useSnackbar';
import cn from './ToDoListForm.module.scss';
import {showSnackbarErrorMessage} from "../../../../services/snackbarService";
import { selectIsAuthenticated } from 'store/microsoftGraph/Selector';

type MapStateToProps = {
  selectedSupplierId: SupplierId;
  userName: string;
  responsibleSales: ResponsibleSalesList;
};

type MapDispatchToProps = {
  addToDo: (supplierId: SupplierId, toDo: ToDo) => void;
};

type ToDoListFormProps = {
  onToDoAdd?: () => void;
} & MapStateToProps & MapDispatchToProps;

const ToDoListFormComponent = React.memo(({
  selectedSupplierId, userName, addToDo, onToDoAdd, responsibleSales
}: ToDoListFormProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [inputValue, setInputValue] = useState('');
  const [mentions, setMentions] = useState<SuggestionDataItem[]>([]);

  const supplier = useSelector(selectSelectedSupplier);

  const {
    enable,
    startTime,
    endTime,
    notificationTime,
    onEnableChange,
    onStartDateChange,
    onEndDateChange,
    onNotificationTimeChange,
  } = useNotification({});
  const { postEvent } = useCalendar();
  const authenticated = useSelector(selectIsAuthenticated);

  function handleDateChange(date: MaterialUiPickersDate): void {
    setSelectedDate(date as Date);
  }

  const handleInputChange: OnChangeHandlerFunc = (event, newValue, newPlainTextValue, inputMentions): void => {
    setInputValue(newPlainTextValue);
    setMentions([...mentions, ...inputMentions]);
  };

  async function handleAddToDo(): Promise<void> {
    if (!selectedDate || !inputValue || !selectedSupplierId) {
      return;
    }
    const addedToDo: ToDo = {
      id: DateService.generateGuid(),
      createdAt: format(new Date(), 'yyyy-MM-dd HH:mm'),
      createdBy: userName,
      supplierCardId: selectedSupplierId,
      comment: inputValue,
      mentions: mentions.map((mention) => mention.id.toString()),
      date: format(selectedDate, 'yyyy-MM-dd'),
      done: false,
    };
    startLoading();
    try {
      await addToDo(selectedSupplierId, addedToDo);
      showSnackbar({
        message: 'Task was successfully created.',
        type: 'success',
      });
      if (enable) {
        const attendees: Attendee[] = [
          ...mentions.reduce((currentValue: Attendee[], mention) => {
            const sale = responsibleSales.find((sale) => sale.id === mention.id);
            if (sale) {
              return [
                ...currentValue,
                {
                  emailAddress: { address: sale.email, name: sale.displayName },
                  type: 'optional',
                },
              ];
            }
            return currentValue;
          }, []),
        ];

        if (authenticated) {
          const event: Event = {
            subject: `[${supplier ? supplier.name : ''}] To Do`,
            body: { content: addedToDo.comment },
            start: { dateTime: setHours(setMinutes(selectedDate, getMinutes(startTime)), getHours(startTime)) },
            end: { dateTime: setHours(setMinutes(selectedDate, getMinutes(endTime)), getHours(endTime)) },
            isReminderOn: true,
            reminderMinutesBeforeStart: notificationTime,
            attendees,
          };
          await postEvent(event);
        }
      }
      if (onToDoAdd) {
        onToDoAdd();
      }
    } catch (err) {
      showSnackbarErrorMessage(err)
    } finally {
      setSelectedDate(new Date());
      setInputValue('');
      setMentions([]);
      finishLoading();
    }
  }

  const responsibleSalesMentions: SuggestionDataItem[] = useMemo(() => responsibleSales.map((user) => ({
    ...user,
    display: user.displayName,
  })), [responsibleSales]);

  return (
    <TableHead>
      <TableRow
        classes={{ root: cn.tableHeaderRow }}
      >
        <TableCell
          classes={{ head: cn.tableHeaderCell }}
          padding="none"
        >
          <div>
            <div className={cn.label}>Date:</div>
            <div className={cn.tableDateCell}>
              <DatePicker
                value={selectedDate}
                format="yyyy-MM-dd"
                onChange={handleDateChange}
                animateYearScrolling={false}
                className={cn.tableHeaderDatePicker}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <div className={cn.calendarAdornment}>
                      <CalendarTodayOutlined className={cn.calendarIcon} />
                    </div>
                  ),
                }}
              />
              <Notification
                enable={enable}
                startTime={startTime}
                endTime={endTime}
                notificationTime={notificationTime}
                onEnableChange={onEnableChange}
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
                onNotificationTimeChange={onNotificationTimeChange}
              />
            </div>
          </div>
        </TableCell>
        <TableCell
          classes={{ head: cn.tableHeaderSecondCell }}
          padding="none"
        >
          <div>
            <div className={cn.label}>Task:</div>
            <MentionsInput
              className={cn.tableHeaderInput}
              value={inputValue}
              onChange={handleInputChange}
            >
              <Mention
                trigger="@"
                data={responsibleSalesMentions}
                displayTransform={(id, display): string => `@${display}`}
                appendSpaceOnAdd
              />
            </MentionsInput>
          </div>
        </TableCell>
        <TableCell
          classes={{ head: classNames(cn.tableHeaderCell, cn.addButtonCell) }}
        >
          <button
            type="button"
            className={cn.tableHeaderButton}
            onClick={handleAddToDo}
          >
            Add
          </button>
        </TableCell>
      </TableRow>
    </TableHead>
  );
});

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedSupplierId: selectSelectedSupplierId(state),
  userName: selectUserName(state),
  responsibleSales: selectResponsibleSales(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  addToDo: addToDoAction,
};

export const ToDoListForm = connect(mapStateToProps, mapDispatchToProps)(ToDoListFormComponent);
