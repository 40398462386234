import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import api from 'api';
import { LogInput } from 'components/SupplierCard/Log/LogInput/LogInput';
import { LogList } from 'components/SupplierCard/Log/LogList/LogList';
import { selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { selectUserName } from 'store/authorization/Selector';
import { selectSupplierLogs } from 'store/logs/Selector';
import { selectIsMenuOpened } from 'store/actionBar/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import { handleLogsDownload as handleLogsDownloadAction } from 'store/logs/ActionCreator';
import { LogList as Logs } from 'store/logs/types';
import { SupplierId } from 'store/suppliersList/types';
import { AppState } from 'store/RootReducer';
import { showSnackbar } from 'hooks/useSnackbar';
import cn from './Log.module.scss';
import { showSnackbarErrorMessage } from 'services/snackbarService';

type MapStateToProps = {
  isMenuOpen: boolean;
  logs: Logs;
  selectedSupplierId: SupplierId;
  userName: string;
};

type MapDispatchToProps = {
  handleLogsDownload: (supplierId: SupplierId) => void;
};

type LogComponentProps = MapStateToProps & MapDispatchToProps;

class LogComponent extends React.Component<LogComponentProps, {logInputValue: string}> {
  state = {
    logInputValue: '',
  };

  componentDidMount(): void {
    this.requestLogsData();
  }

  async componentDidUpdate(prevProps: LogComponentProps): Promise<void> {
    const { selectedSupplierId } = this.props;
    if (selectedSupplierId && prevProps.selectedSupplierId !== selectedSupplierId) {
      await this.requestLogsData();
    }
  }

  requestLogsData = (): void => {
    const { handleLogsDownload, selectedSupplierId } = this.props;
    try {
      handleLogsDownload(selectedSupplierId);
      this.setState(() => ({
        logInputValue: '',
      }));
    } catch (err) {
      showSnackbarErrorMessage(err);
    }
  };

  handleChangeLogInput = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = event.target;
    this.setState(() => ({
      logInputValue: value,
    }));
  };

  handleAddLog = async (): Promise<void> => {
    const { handleLogsDownload, selectedSupplierId } = this.props;
    if (!selectedSupplierId) {
      return;
    }
    const addedLog = {
      createdAt: format(new Date(), 'yyyy-MM-dd HH:mm'),
      createdBy: this.props.userName,
      supplierCardId: selectedSupplierId,
      comment: this.state.logInputValue,
    };
    startLoading();
    try {
      await api.addSupplierLog(selectedSupplierId, addedLog);
      showSnackbar({
        message: 'Log was successfully added.',
        type: 'success',
      });
      this.setState(() => ({
        logInputValue: '',
      }));
      await handleLogsDownload(selectedSupplierId);
    } catch (err) {
      showSnackbarErrorMessage(err);
    }
    finishLoading();
  };

  render() {
    const { logs } = this.props;
    const { logInputValue } = this.state;
    return (
      <div className={cn.root}>
        <LogInput
          logInputValue={logInputValue}
          handleChangeLogInput={this.handleChangeLogInput}
          handleAddLog={this.handleAddLog}
        />
        <LogList
          supplierLogs={logs}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): MapStateToProps => ({
  isMenuOpen: selectIsMenuOpened(state),
  logs: selectSupplierLogs(state),
  selectedSupplierId: selectSelectedSupplierId(state),
  userName: selectUserName(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  handleLogsDownload: handleLogsDownloadAction,
};

export const Log = connect(mapStateToProps, mapDispatchToProps)(LogComponent);
