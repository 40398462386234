import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {
  AppBar, Badge, Toolbar, Tooltip,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { selectUserInitials } from 'store/authorization/Selector';
import { AppState } from 'store/RootReducer';
import constants from 'constants/MenuApps';
import routeConstants from 'constants/Routes';
import MenuIcon from 'img/ic-app-menu.svg';
import logoutIcon from 'img/logout.svg';
import refreshIcon from 'img/refresh.png';
import { logout as logoutAction, removeCookies } from 'store/authorization/ActionCreator';
import { useMicrosoftAuth } from 'hooks/useMicrosoftAuth';
import { selectIsAuthenticated } from 'store/microsoftGraph/Selector';
import cn from './MenuAppBar.module.scss';
import SRMSearchBar from 'components/common/SearchBar/SearchBar';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'api/msalConfig'

type MapStateToProps = {
  userInitials: string;
};

type MapDispatchToProps = {
  logout: (callback?: () => {}) => void;
};

type OwnProps = {
  handleMenuToggle: () => void;
};

interface MenuAppBarProps extends MapStateToProps, MapDispatchToProps, OwnProps {
}

export const MenuAppBarComponent = React.memo(
  ({
    userInitials, handleMenuToggle, logout,
  }: MenuAppBarProps) => {
    const authenticated = useSelector(selectIsAuthenticated);
    const history = useHistory();
    const { logout: microsoftLogout, login } = useMicrosoftAuth();

    async function handleLogout(): Promise<void> {
      removeCookies();
      logout();
      await microsoftLogout();
      var msalInstance = new PublicClientApplication(msalConfig);
      msalInstance.logoutRedirect();
      history.push(routeConstants.loginPage);
    }

    function refreshConnection() {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

        var msalTokenPattern = /^msal.*token$/;
        Object.keys(localStorage)
            .filter(key => msalTokenPattern.test(key))
            .forEach(key => localStorage.removeItem(key));

        window.location.reload();
    }

    return (
      <AppBar position="static" classes={{ root: cn.root }}>
        <Toolbar className={cn.toolbar}>
          <button id="menuButton" type="button" onClick={handleMenuToggle} className={cn.menuIconButton}>
            <img src={MenuIcon} alt="MenuIcon" />
          </button>
          <div className={cn.selectedAppSection}>SRM</div>
          <SRMSearchBar />
          <div className={cn.profileSection}>
            <div
              id="refreshButton"
              className={cn.profileSectionRefresh}
              onClick={refreshConnection}
            >
              <img src={refreshIcon} alt="refreshImage" className={cn.refreshIcon} />
            </div>
            <Badge
              classes={{ badge: authenticated ? cn.badgeSuccess : cn.badgeWarning }}
              badgeContent={authenticated
                ? (
                  <Tooltip title="Logged in to SharePoint">
                    <CheckIcon fontSize="small" className={cn.badgeSuccessIcon} />
                  </Tooltip>
                )
                : (
                  <Tooltip title="You are not logged in into SharePoint, click to login">
                    <WarningIcon fontSize="small" className={cn.badgeWarningIcon} onClick={login} />
                  </Tooltip>
                )}
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Link to={routeConstants.myPage} className={cn.profileSectionProfileButton}>
                {userInitials}
              </Link>
            </Badge>
            <div
              id="logoutButton"
              className={cn.profileSectionLogout}
              onClick={handleLogout}
            >
              <img src={logoutIcon} alt="logoutImage" className={cn.logoutIcon} />
              <span className={cn.profileSectionLogoutText}>{constants.logout}</span>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  },
);

const mapStateToProps = (state: AppState): MapStateToProps => ({
  userInitials: selectUserInitials(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  logout: logoutAction,
};

export const MenuAppBar = connect(mapStateToProps, mapDispatchToProps)(MenuAppBarComponent);
