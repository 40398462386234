import { Action } from 'redux';

export interface FilesState {
  [folder: string]: Array<File>;
}

export enum FilesActionType {
  ADD_FILES = 'ADD_FILES',
  REMOVE_FILE = 'REMOVE_FILE',
}

export interface AddFilesAction extends Action<typeof FilesActionType.ADD_FILES> {
  folderId: number;
  files: Array<File>;
}

export interface RemoveFileAction extends Action<typeof FilesActionType.REMOVE_FILE> {
  folderId: number;
  file: File;
}

export type FilesActions = AddFilesAction | RemoveFileAction;
