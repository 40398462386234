import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActiveOutlined';
import { IconButton, Popover } from '@material-ui/core';
import { NotificationForm } from 'components/SupplierCard/ToDoList/NotificationForm/NotificationForm';
import { NotificationFormProps } from 'components/SupplierCard/ToDoList/NotificationForm/types';
import { selectIsAuthenticated } from 'store/microsoftGraph/Selector';
import cn from './Notification.module.scss';

type NotificationProps = NotificationFormProps;

export const Notification = React.memo((props: NotificationProps) => {
  const authorized = useSelector(selectIsAuthenticated);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function onClick(event: any): void {
    setAnchorEl(event.currentTarget);
  }

  return (
    <div>
      <IconButton onClick={onClick} disabled={!authorized}>
        {authorized ? (
          <NotificationsActiveIcon htmlColor={!props.enable ? '#d50000': '#4caf50' } />
        ) : (
          <NotificationsActiveIcon />
        )}
      </IconButton>
      {authorized && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={(): void => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            root: cn.popoverRoot,
            paper: cn.popoverPaper,
          }}
        >
          <NotificationForm
            {...props}
          />
        </Popover>
      )}
    </div>
  )
});
