import React, { useState, useEffect, ChangeEvent } from 'react';
import { connect, useSelector } from 'react-redux';
import { Grid, TableCell, TableRow, TableBody, Dialog, DialogActions, Button as MaterialButton, DialogContent } from '@material-ui/core';
import { ReactSelect } from 'components/common/VirtualizedSelect/ReactSelect';
import {
  selectSelectedSupplier,
} from 'store/suppliersList/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import { editSupplierData as editSupplierDataAction } from 'store/suppliersList/ActionCreator';
import { OtherCertificatesList, Supplier, SupplierCardFile } from 'store/suppliersList/types';
import { AppState } from 'store/RootReducer';
import { showSnackbar } from 'hooks/useSnackbar';
import cn from './SupplierCardDetails.module.scss';
import { showSnackbarErrorMessage } from 'services/snackbarService';
import { DatePicker } from '@material-ui/pickers';
import { CalendarTodayOutlined, Add } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Button } from 'components/common/Button/Button';
import { UploadButton } from 'components/SupplierCard/Tabs/FilesTab/UploadButton/UploadButton';
import { OtherCertificateRow } from 'components/SupplierCard/SupplierCardDetails/OtherDocumentsComponent/OtherDocumentsComponent';
import { selectIsAuthenticated } from 'store/microsoftGraph/Selector';
import { DriveItem, useFiles } from 'hooks/useFiles';
import { ConfirmRenameModal } from '../Tabs/FilesTab/ConfirmRenameModal/ConfirmRenameModal';

type MapStateToProps = {
  selectedSupplier?: Supplier;
};

type MapDispatchToProps = {
  editSupplierData: (supplierId: string, supplier: Supplier) => void;
};

type Option = {
  value: string;
  label: string;
};

type SupplierCardDetailsProps = MapStateToProps & MapDispatchToProps;

var uploadingOtherCertificatesFiles: Array<File> = [];
var otherCertificatesHidden: OtherCertificatesList = [];

const SupplierCardDetailsComponent = React.memo(({
  selectedSupplier,
  editSupplierData,
}: SupplierCardDetailsProps) => {
  const [name, setName] = useState('');
  const {
    getFiles, uploadFile, createDefaultFolders
  } = useFiles();
  const [insuranceFiles, setInsuranceFiles] = useState<Array<DriveItem>>([]);
  const [otherCertificatesFiles, setOtherCertificatesFiles] = useState<Array<DriveItem>>([]);
  const [otherCertificates, setOtherCertificates] = useState<OtherCertificatesList>(selectedSupplier?.supplierCardOtherFiles || []);
  const [insuranceDate, setInsuranceDate] = useState(selectedSupplier?.supplierCardInsurance?.validUntil as Date || null);
  const [insuranceFileName, setInsuranceFileName] = useState(selectedSupplier?.supplierCardInsurance?.filename as string || '');
  const [confirmOpenInsurance, setConfirmOpenInsurance] = useState(false);
  const [confirmDeleteRows, setConfirmDeleteRows] = useState(false);
  const [newInsuranceFileName, setNewInsuranceFileName] = useState('');
  const [uploadedInsuranceFiles, setUploadedInsuranceFiles] = useState<File[]>([]);
  const [uploadingInsuranceFile, setUploadingInsuranceFile] = useState<File>();
  const authenticated = useSelector(selectIsAuthenticated);
  const [insuranceDateError, setInsuranceDateError] = useState(selectedSupplier?.supplierCardInsurance?.isDateError)
  const [insuranceFileError, setInsuranceFileError] = useState(selectedSupplier?.supplierCardInsurance?.isFileError)


  const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setName(value);
  };

  const fetchFiles = async (): Promise<void> => {
    var myFolders = await getFiles()
    if (myFolders.length !== 0) {
      var myInsuranceFiles = await getFiles(myFolders[0].id);  // Set myInsurance as first folder files ('1. Insurance')
      var myOtherCertificatesFiles = await getFiles(myFolders[5].id);  // Set myOther as 6th folder files ('6. Other')
      setInsuranceFiles(myInsuranceFiles);
      setOtherCertificatesFiles(myOtherCertificatesFiles);
    }
    else {
      setInsuranceFiles([]);
      setOtherCertificatesFiles([]);
    }
  }

  const handleDateChange = (date: MaterialUiPickersDate): void => {
    setInsuranceDate(date as Date);
  }

  const handleInsuranceFileNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setInsuranceFileName(value);
  }


  function handleProceed() {
    handleConfirmDeleteRowsClose();
    RemoveEmptyOtherFiles();
    handleSaveButton()
  }

  function handleCancel() {
    handleConfirmDeleteRowsClose();
  }


  const handleSaveButton = async (): Promise<void> => {
    if (!selectedSupplier) {
      return;
    }
    startLoading();
    var folders = await getFiles()
    var defaultFolders = {
      id: selectedSupplier.id,
      children: folders
    };
    try {
      if (folders.length === 0) {
        try {
          var defaultFolders = await createDefaultFolders(name);
          await fetchFiles();
        } catch (err) {
          showSnackbar({
            message: 'Folders could not be created due to internal error. Please, contact your System Administrator.',
            type: 'error',
          });
        }
      }
      if (otherCertificatesHidden && otherCertificatesHidden.filter(otherFile => otherFile.filename === '' && otherFile.validUntil === null).length > 0) {
        finishLoading();
        setConfirmDeleteRows(true)
        return;
      }
      var insurance: SupplierCardFile = {
        validUntil: insuranceDate,
        filename: insuranceFileName === "" ? null : insuranceFileName,
        uploadDate: new Date(),
        supplierCardId: selectedSupplier?.id
      }
      var addedSupplier = {
        ...selectedSupplier,
        supplierCardInsurance: insurance,
        supplierCardOtherFiles: otherCertificatesHidden,
        name,
      };
      var insuranceFolder = folders[0]?.id ?? defaultFolders.children[0].id;
      var otherFileFolder = folders[5]?.id ?? defaultFolders.children[5].id;
      addedSupplier = RemoveEmptyAllFiles(addedSupplier)

      const updatedCertificates = [...(addedSupplier.supplierCardOtherFiles ?? [])];
      addedSupplier = resetErrors(addedSupplier);
      var resFile = addedSupplier.supplierCardOtherFiles?.filter(x => x.filename === '');
      var resDate = addedSupplier.supplierCardOtherFiles?.filter(x => x.validUntil === null);
      if (updatedCertificates.length > 0) {
        [resFile, resDate].forEach(result => {
          if (result?.length > 0) {
            updatedCertificates.forEach(x => {
              if (result?.map(item => item.keyValue).includes(x.keyValue)) {
                if (result === resFile) {
                  x.isFileError = true;
                }
                if (result === resDate) {
                  x.isDateError = true;
                }
              }
            });
            setOtherFiles(updatedCertificates);
          }
        });
      }
      if (name.trim() === "" || name.trim().length === 0) {
        showSnackbar({
          message: "Supplier name can not be empty",
          type: 'error',
          autoHideDuration: 10000
        });
      } else if (addedSupplier.supplierCardInsurance?.validUntil === null) {
        setInsuranceDateError(true)
        showSnackbar({
          message: "Insurance date can not be empty",
          type: 'error',
          autoHideDuration: 10000
        });
      } else if (addedSupplier.supplierCardInsurance?.filename === '' || addedSupplier.supplierCardInsurance?.filename === null) {
        setInsuranceFileError(true)
        showSnackbar({
          message: "Insurance file can not be empty",
          type: 'error',
          autoHideDuration: 10000
        });
      } else if (addedSupplier.supplierCardOtherFiles?.find(c => c.validUntil === null)) {
        showSnackbar({
          message: "Other certificates date can not be empty",
          type: 'error',
          autoHideDuration: 10000
        });
      } else if (addedSupplier.supplierCardOtherFiles?.find(c => c.filename === '')) {
        addedSupplier.supplierCardOtherFiles?.find(c => c.filename === '')
        showSnackbar({
          message: "Other certificates file can not be empty",
          type: 'error',
          autoHideDuration: 10000
        });
      } else {
        if (uploadingInsuranceFile !== undefined) {
          await handleInsuranceFileUpload(uploadingInsuranceFile, insuranceFolder);
          setUploadingInsuranceFile(undefined);
        }
        if (uploadingOtherCertificatesFiles.length > 0) {
          await handleOtherCertificatesUpload(uploadingOtherCertificatesFiles, otherFileFolder);
          uploadingOtherCertificatesFiles = [];
        }
        await fetchFiles();
        await editSupplierData(selectedSupplier.id, addedSupplier);
        if (selectedSupplier !== undefined) {
          selectedSupplier.supplierCardOtherFiles = addedSupplier.supplierCardOtherFiles;
          selectedSupplier.supplierCardInsurance = addedSupplier.supplierCardInsurance;
        }
        if (selectedSupplier.supplierCardInsurance != null) {
          setInsuranceDateError(false);
          setInsuranceFileError(false);
        }
        showSnackbar({
          message: 'Supplier info was successfully updated.',
          type: 'success',
        });
      }
    } catch (err) {
      showSnackbarErrorMessage(err);
    }
    finishLoading();
  };

  const handleConfirmCloseInsurance = (): void => {
    setConfirmOpenInsurance(false);
    setNewInsuranceFileName('');
  };

  const handleConfirmDeleteRowsClose = (): void => {
    setConfirmDeleteRows(false);
  };

  function resetErrors(supplier: Supplier): Supplier {
    if (supplier.supplierCardInsurance != null) {
      setInsuranceDateError(false);
      setInsuranceFileError(false);
    }
    supplier.supplierCardOtherFiles?.map(x => {
      x.isDateError = false;
      x.isFileError = false;
    })
    return supplier;
  }

  function RemoveEmptyOtherFiles() {
    if (otherCertificates !== undefined) {
      var value = otherCertificates.filter(otherFile => otherFile.filename !== '' || otherFile.validUntil !== null)
      setOtherFiles(value);
    }
  }

  function RemoveEmptyAllFiles(addedSupplier: any): any {
    if (addedSupplier.supplierCardInsurance?.filename === null &&
      addedSupplier.supplierCardInsurance.validUntil === null)
      addedSupplier.supplierCardInsurance = null;
    if (addedSupplier.supplierCardOtherFiles?.length === 0)
      addedSupplier.supplierCardOtherFiles = null;
    return addedSupplier;
  }

  useEffect(() => {
    if (!selectedSupplier) {
      return;
    }
    const { name: supplierName, supplierCardInsurance: insurance, supplierCardOtherFiles: otherFiles } = selectedSupplier;
    setName(supplierName);
    setInsuranceDate(insurance?.validUntil as Date || null);
    setInsuranceFileName(insurance?.filename as string || "");
    if (otherFiles !== null) {
      for (let i = 1; i < otherFiles?.length; i++) {
        otherFiles[i - 1].keyValue = i;
      }
    }
    setOtherCertificates(otherFiles);
    otherCertificatesHidden = otherFiles
    fetchFiles();
    uploadingOtherCertificatesFiles = [];
    setInsuranceDateError(false);
    setInsuranceFileError(false);
  }, [selectedSupplier]);

  const handleInsuranceFileUploading = async (file: File): Promise<void> => {
    setUploadingInsuranceFile(file);
    setInsuranceFileName(file.name);
  };

  const handleInsuranceFileUpload = async (file: File, id: number): Promise<void> => {
    try {
      setUploadedInsuranceFiles([...uploadedInsuranceFiles, file]);
      await uploadFile(id, file);  // Upload to the 1st folder ('1. Insurance')
    } catch (err) {
    }
  }

  const handleOtherCertificatesUpload = async (file: File[], id: number): Promise<void> => {
    let otherFiles = otherCertificatesHidden;
    if (otherFiles === undefined || otherFiles.length === 0)
      otherFiles = [{}];

    otherFiles[0].uploadDate = new Date();
    try {
      uploadingOtherCertificatesFiles.forEach(async (fileToUpload) => {
        await uploadFile(id, fileToUpload);
      });
    } catch (err) {
    }

  }

  const handleInsuranceFileChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { files: inputFiles } = event.target;
    await newFileNameHandle(inputFiles, true);
  };

  const handleOtherCertificatesChange = async (event: ChangeEvent<HTMLInputElement>, keyValue: number = 0, validUntil: Date): Promise<void> => {
    const { files: inputFiles } = event.target;
    await newFileNameHandle(inputFiles, false, keyValue, validUntil);
  };

  function handleOtherCertificatesDelete(event: ChangeEvent<HTMLInputElement>, keyValue: number) {
    var fileToRemove = [...otherCertificates].filter((_item, index) => index === keyValue)
    setOtherFiles([...otherCertificates].filter((_item, index) => index !== keyValue));
    uploadingOtherCertificatesFiles = uploadingOtherCertificatesFiles.filter(item => item.name !== fileToRemove[0]?.filename);
  };

  const setOtherCertificatesItem = (fileName: any, fileDate: any, index: any) => {
    var otherFiles = [];
    if (otherCertificates && otherCertificates.length > 0)
      otherFiles = [...otherCertificates];
    else
      otherFiles.push({});
    otherFiles[index].filename = fileName;
    otherFiles[index].validUntil = fileDate;
    otherFiles[index].uploadDate = new Date();
    otherFiles[index].supplierCardId = selectedSupplier?.id;
    setOtherFiles(otherFiles)
  }

  function handleAddRow() {
    const newItem: SupplierCardFile = {
      id: 0,
      filename: "",
      validUntil: null,
      uploadDate: null,
      supplierCardId: selectedSupplier?.id,
      keyValue: otherCertificates?.length + 1
    };
    setOtherFiles([...(otherCertificates || []), newItem])
  }

  function setOtherFiles(otherFiles: any) {
    if (otherFiles !== null) {
      for (let i = 1; i < otherFiles?.length; i++) {
        otherFiles[i - 1].keyValue = i;
      }
    }
    otherCertificatesHidden = otherFiles;
    setOtherCertificates([...otherFiles]);
  }

  async function newFileNameHandle(inputFiles: FileList | null, isInsurance: boolean, keyValue: number = 0, validUntil: any = null) {
    if (!inputFiles || inputFiles.length === 0) {
      return;
    }
    const file = Array.from(inputFiles || [])[0];
    let i = 1;
    const nameParts = file.name.split('.');
    const format = nameParts.pop();
    let newName = `${nameParts.join('.')}_(${i}).${format}`;
    var isNewName = false;
    if (isInsurance) {
      if (insuranceFiles.find((f) => f.name === file.name)) {
        isNewName = true;
        while (insuranceFiles.find((f) => f.name === newName)) {
          newName = `${nameParts.join('.')}_(${++i}).${format}`;
        }
      }
    }
    else {
      if (otherCertificatesFiles.find((f) => f.name === file.name)) {
        isNewName = true;
        while (otherCertificatesFiles.find((f) => f.name === newName)) {
          newName = `${nameParts.join('.')}_(${++i}).${format}`;
        }
      }
    }
    if (!isInsurance && uploadingOtherCertificatesFiles.find((f) => f.name === file.name || f.name === newName)) {
      isNewName = true;
      while (uploadingOtherCertificatesFiles.find((f) => f.name === newName)) {
        newName = `${nameParts.join('.')}_(${++i}).${format}`;

      }
    }
    if (isNewName === true) {
      if (isInsurance) {
        setInsuranceFileName(newName);
        setNewInsuranceFileName(newName);
        setUploadingInsuranceFile(file);
        setConfirmOpenInsurance(true);
        return;
      } else {
        await setOtherCertificatesItem(newName, otherCertificatesHidden[keyValue]?.validUntil ?? validUntil, keyValue)
        const newFile = new File([file], newName);
        uploadingOtherCertificatesFiles.push(newFile);

        return;
      }
    }

    if (isInsurance) {
      setInsuranceFileName(file.name);
      setUploadingInsuranceFile(file);
    } else {
      await setOtherCertificatesItem(file.name, otherCertificatesHidden[keyValue]?.validUntil ?? validUntil, keyValue)

      uploadingOtherCertificatesFiles.push(file)
    }
  }

  return (
    <Grid container className={cn.container}>
      <div className={cn.supplierName}>Name:</div>
      <Grid item xs={5} className={cn.supplierInfo}>
        <input
          type="search"
          className={cn.headerInput}
          value={name}
          onChange={handleNameChange}
        />
      </Grid>
      <table className={cn.certificatesTable}>
        <TableBody>
          <TableRow classes={{ root: cn.tableBodyRow }}>
            <TableCell classes={{ head: cn.tableBodyCell }} padding="none">
              <div className={cn.label}>Valid CMR insurance:</div>
              <div className={cn.tableDateCell}>
                <DatePicker
                  value={insuranceDate}
                  format="yyyy-MM-dd"
                  onChange={handleDateChange}
                  animateYearScrolling={false}
                  className={insuranceDateError !== true ? cn.tableBodyDatePicker : cn.tableBodyDatePicker + " " + cn.error}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <div className={cn.calendarAdornment}>
                        <CalendarTodayOutlined className={cn.calendarIcon} />
                      </div>
                    ),
                  }}
                  disabled={!authenticated}
                />
              </div>
            </TableCell>
            <TableCell
              classes={{ head: cn.tableBodySecondCell }}
              padding="none"
            >
              <input
                type="string"
                className={insuranceFileError !== true ? cn.tableBodyInput : cn.tableBodyInput + " " + cn.error}
                value={insuranceFileName || ''}
                onChange={handleInsuranceFileNameChange}
                disabled={!authenticated}
                readOnly={true}
              />
            </TableCell>
            <TableCell
              classes={{ head: cn.tableBodySecondCell }}
              padding="none"
            >
              <UploadButton
                onChange={handleInsuranceFileChange}
                id="insurance"
                className={cn.icon}
                disabled={!authenticated}
              />
            </TableCell>
            {confirmOpenInsurance && (
              <ConfirmRenameModal
                open={confirmOpenInsurance}
                file={uploadingInsuranceFile}
                newFileName={newInsuranceFileName}
                onSubmit={handleInsuranceFileUploading}
                onClose={handleConfirmCloseInsurance}
              />
            )}
          </TableRow>
        </TableBody>
      </table>

      <table className={cn.certificatesTable}>
        <TableBody>
          <TableRow classes={{ root: cn.tableBodyRow }} key="0">
            <TableCell classes={{ head: cn.tableBodyCell }} padding="none">
              <div className={cn.label}>Other certificates:
                <div className={cn.buttonRow}>
                  <Button
                    onClick={handleAddRow}
                    color="primary"
                    className={cn.button}
                    disabled={!authenticated}
                  >
                    <Add className={cn.newSupplierPlusIcon} />
                  </Button>
                </div>
              </div>
            </TableCell>
          </TableRow>
          {otherCertificates?.map((item, index) => (
            <OtherCertificateRow
              key={index}
              keyValue={index}
              otherCertificatesItem={item}
              setOtherCertificatesItemChild={setOtherCertificatesItem}
              handleOtherCertificatesChange={handleOtherCertificatesChange}
              handleOtherCertificatesDelete={handleOtherCertificatesDelete}
            />
          ))}
          {confirmDeleteRows && (
            <Dialog
              open={confirmDeleteRows}
              onClose={handleConfirmDeleteRowsClose}
            >
              <DialogContent>
                <div className={cn.content} data-role="content">
                  <span>There are empty Other certificates. Before saving they will be discarded and removed. Continue?</span>
                </div>
              </DialogContent>
              <DialogActions>
                <MaterialButton onClick={handleProceed} data-role="rename">Proceed</MaterialButton>
                <MaterialButton onClick={handleCancel} data-role="replace">Cancel</MaterialButton>
              </DialogActions>
            </Dialog>
          )}
        </TableBody>
      </table>
      <Grid item className={cn.headerSaveButtonRoot}>
        <button
          type="button"
          className={cn.headerSaveButton}
          onClick={handleSaveButton}
        >
          Save
        </button>
      </Grid>
    </Grid>
  );
});

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedSupplier: selectSelectedSupplier(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  editSupplierData: editSupplierDataAction,
};

export const SupplierCardDetails = connect(mapStateToProps, mapDispatchToProps)(SupplierCardDetailsComponent);
