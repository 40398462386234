import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { SaveToDoModal } from 'components/SupplierCard/ToDoList/SaveToDoModal/SaveToDoModal';
import { ToDo, ToDoList } from 'store/toDoList/types';
import { SupplierId } from 'store/suppliersList/types';
import { AppState } from 'store/RootReducer';
import { selectToDoList } from 'store/toDoList/Selector';
import {
  getToDoList as getToDoListAction,
  completeToDo as completeToDoAction,
} from 'store/toDoList/ActionCreator';
import { selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import cn from './ToDoListBody.module.scss';

type MapStateToProps = {
  selectedSupplierId: SupplierId;
  toDoList: ToDoList;
}

type MapDispatchToProps = {
  getToDoList: (supplierId: SupplierId) => void;
  completeToDo: (supplierId: SupplierId, toDo: ToDo) => void;
}

type ToDoListBodyProps = {
  onToDoDone?: (toDo: ToDo, comment: string) => void;
} & MapStateToProps & MapDispatchToProps;

const ToDoListBodyComponent = React.memo(({
  selectedSupplierId, toDoList, getToDoList, onToDoDone, completeToDo,
}: ToDoListBodyProps) => {
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [doneToDo, setDoneToDo] = useState<ToDo>();

  const handleToDoDone = (toDo: ToDo): void => {
    setDoneToDo(toDo);
    setSaveModalOpen(true);
  };

  function handleModalToggle(): void {
    setSaveModalOpen(!saveModalOpen);
  }

  function handleCancel(): void {
    setDoneToDo(undefined);
    setSaveModalOpen(false);
  }

  async function handleSave(value: string): Promise<void> {
    if (!selectedSupplierId || !doneToDo) {
      return;
    }
    startLoading();
    try {
      await completeToDo(selectedSupplierId, doneToDo);
      if (onToDoDone) {
        onToDoDone(doneToDo, value);
      }
    } catch (err) {
    } finally {
      finishLoading();
      setSaveModalOpen(false);
    }
  }

  useEffect(() => {
    const requestData = async (): Promise<void> => {
      await getToDoList(selectedSupplierId);
    };
    requestData();
  }, [selectedSupplierId, getToDoList]);
  
  return (
    <TableBody>
      {toDoList.map(supplierToDoTask => (
        <TableRow
          key={supplierToDoTask.id}
          classes={{
            root: cn.tableRow,
          }}
        >
          <TableCell padding="none">
            <span className={cn.tableRowText}>{supplierToDoTask.date}</span>
          </TableCell>
          <TableCell
            classes={{
              body: cn.tableSecondCell,
            }}
            padding="none"
          >
          <span className={cn.tableRowText}>
            {supplierToDoTask.comment}
          </span>
          </TableCell>
          <TableCell padding="none">
            <button
              type="button"
              className={cn.tableButton}
              onClick={(): void => handleToDoDone(supplierToDoTask)}
            >
              Done
            </button>
          </TableCell>
        </TableRow>
      ))}
      {saveModalOpen && (
        <SaveToDoModal
          isOpened={saveModalOpen}
          toggle={handleModalToggle}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      )}
    </TableBody>
  )
});

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedSupplierId: selectSelectedSupplierId(state),
  toDoList: selectToDoList(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  getToDoList: getToDoListAction,
  completeToDo: completeToDoAction,
};

export const ToDoListBody = connect(mapStateToProps, mapDispatchToProps)(ToDoListBodyComponent);
