import DocIcon from 'img/doc.svg';
import XlsIcon from 'img/xls.svg';
import PdfIcon from 'img/pdf.svg';
import PptIcon from 'img/ppt.svg';
import TxtIcon from 'img/txt.svg';
import HtmlIcon from 'img/html.svg';
import PngIcons from 'img/png.svg';
import XmlIcon from 'img/xml.svg';
import ZipIcon from 'img/zip.svg';
import JpgIcon from 'img/jpg.svg';
import SvgIcon from 'img/svg.svg';

export const FileTypesIcons = {
  'application/msword': DocIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': DocIcon,
  'docx': DocIcon,
  'doc': DocIcon,
  xls: XlsIcon,
  xlsx: XlsIcon,
  pdf: PdfIcon,
  ppt: PptIcon,
  pptx: PdfIcon,
  txt: TxtIcon,
  'text/html': HtmlIcon,
  'image/png': PngIcons,
  'application/pdf': PdfIcon,
  'application/vnd.ms-powerpoint': PptIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': PptIcon,
  'text/plain': TxtIcon,
  'application/vnd.ms-excel': XlsIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': XlsIcon,
  'application/xml': XmlIcon,
  'text/xml': XmlIcon,
  'application/zip': ZipIcon,
  'image/jpeg': JpgIcon,
  'image/svg+xml': SvgIcon,
};
