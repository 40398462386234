const constants = {
  logout: 'Log out',
  planningDesk: 'Planning Desk',
  crm: 'CRM',
  claims: 'Claims',
  terminalHandling: 'Terminal Handling',
  srm: 'SRM',
  myBegoma: 'My Begoma',
};

export default constants;
