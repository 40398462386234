import { showSnackbar } from 'hooks/useSnackbar';

export const showSnackbarErrorMessage = (err) => {
    if(err?.response?.data) {
        showSnackbar({
            message: err.response.data,
            type: 'error',
            autoHideDuration: 10000
        });
    } else if(err?.message) {
        showSnackbar({
            message: err.message,
            type: 'error',
            autoHideDuration: 10000
        });
    } else {
        showSnackbar({
            message: "Oops! Something went wrong. Please try later.",
            type: 'error',
            autoHideDuration: 10000
        });
    }
}