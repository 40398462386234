import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { Delete } from '@material-ui/icons';
import cn from './DeleteButton.module.scss';

type DeleteButtonProps = {
  onClick: (event: MouseEvent<HTMLInputElement>) => void;
  id: string | number;
  label?: string;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'secondary' | 'default';
  className?: string;
  disabled?: boolean;
}

export function DeleteButton({ onClick, id, label, className, disabled }: DeleteButtonProps) {
  function handleClick(event: any): void {
    event.stopPropagation();
    if (!disabled)
      onClick(event);
  }

  return (
    <div className={classNames(cn.root, className)}>
      <input
        className={cn.input}
        id={`delete-file-${id}`}
        type="button"
        value={''}
      />
      {label ? (
        <label htmlFor={`delete-file-${id}`} className={cn.deleteButtonWithLabel} onClick={handleClick}>
          <Delete htmlColor="#FFFFFF" className={cn.icon}/>
          <div>{label}</div>
        </label>
      ) : (
        <label htmlFor={`delete-file-${id}`} className={cn.deleteButton} onClick={handleClick}>
          <Delete htmlColor="#0072ba" className={cn.icon}/>
        </label>
      )}
    </div>
  );
}
