import { Action } from 'redux';
import { ResponsibleSale } from 'store/responsibleSales/types';

export interface Supplier {
  id: string;
  name: string;
  isActive: boolean;
  turnover: number;
  tb1: number;
  nrOfShipments: number;
  nextToDo: string | null;
  supplierCardInsurance: SupplierCardFile;
  supplierCardOtherFiles: OtherCertificatesList;
}

export interface Customer {
  id: string;
  name: string;
  url: string;
  isActive: boolean;
  responsibleSales?: ResponsibleSale;
  turnover: number;
  tb1: number;
  nrOfShipments: number;
  nextToDo: string | null;
  responsibleDisplayName?: string | null;
}

export interface SupplierCardFile {
    id?: number;
    filename?: string | null;
    validUntil?: Date | null;
    uploadDate?: Date | null;
    supplierCardId?: string;
    isDateError?: boolean | false;
    isFileError?: boolean | false;
    keyValue?: number;
}

export type OtherCertificatesList = Array<SupplierCardFile>;

export type SupplierId = string;

export type SortColumnId = string;

export type SortAsc = boolean;

export type SuppliersList = Array<Supplier>;

export interface SuppliersListState {
  supplierList: SuppliersList;
  selectedSupplierId: string;
  selectedSortColumnId: string;
  selectedSortAsc: boolean;
}

export enum ActionTypes {
  SET_SUPPLIERS_LIST = 'SET_SUPPLIER_LIST',
  ADD_SUPPLIER = 'ADD_SUPPLIER',
  EDIT_SUPPLIER = 'EDIT_SUPPLIER',
  TOGGLE_ACTIVITY = 'TOGGLE_ACTIVITY',
  SELECT_SUPPLIER = 'SELECT_SUPPLIER',
}

export interface SetSuppliersListAction extends Action<typeof ActionTypes.SET_SUPPLIERS_LIST> {
  suppliersList: SuppliersList;
}

export interface AddSupplierAction extends Action<typeof ActionTypes.ADD_SUPPLIER> {
  supplier: Supplier;
}

export interface EditSupplierAction extends Action<typeof ActionTypes.EDIT_SUPPLIER> {
  supplier: Supplier;
}

export interface ToggleActivityAction extends Action<typeof ActionTypes.TOGGLE_ACTIVITY> {
  active: boolean;
}

export interface SelectSupplierAction extends Action<typeof ActionTypes.SELECT_SUPPLIER> {
   id: SupplierId;
}

export type SuppliersListAction =
  SetSuppliersListAction | AddSupplierAction | EditSupplierAction | ToggleActivityAction | SelectSupplierAction;
