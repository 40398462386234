import React, {
  createContext, Dispatch, useContext, useReducer,
} from 'react';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  message: {
    width: '100%',
    textAlign: 'center',
  },
  info: {
    color: 'rgb(13, 60, 97)',
    background: 'rgb(232, 244, 253)',
  },
  warning: {
    color: 'rgb(102, 60, 0)',
    background: 'rgb(255, 244, 229)',
  },
  error: {
    color: 'rgb(97, 26, 21)',
    background: 'rgb(253, 236, 234)',
  },
  success: {
    color: 'rgb(30, 70, 32)',
    background: 'rgb(237, 247, 237)',
  },
});

enum Actions {
  OPEN = 'open',
  CLOSE = 'close',
}

type State = SnackbarProps & { type: 'info' | 'warning' | 'success' | 'error' };

type Options = Partial<State>;

type OpenAction = {
  type: Actions.OPEN;
  options?: Options;
};

type CloseAction = { type: Actions.CLOSE };

type Action = CloseAction | OpenAction;

const INITIAL_STATE: State = {
  open: false,
  message: '',
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'top',
  },
  autoHideDuration: 5000,
  type: 'info',
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case Actions.OPEN:
      return {
        ...state,
        open: true,
        ...action.options,
      };
    case Actions.CLOSE:
      return INITIAL_STATE;
    default:
      return state;
  }
}

const ctx = () => {
  const Context = createContext({});
  let dispatchAction: Dispatch<Action>;

  /* eslint-disable react/prop-types */
  const Provider = ({ initialValue = INITIAL_STATE, children }: {initialValue?: State; children?: any}) => {
    const classes = useStyles();

    const [state, dispatch] = useReducer(reducer, initialValue);

    dispatchAction = dispatch;

    return (
      <Context.Provider value={state}>
        <Snackbar
          open={state.open}
          onClose={() => dispatchAction({ type: Actions.CLOSE })}
          message={state.message}
          anchorOrigin={{
            horizontal: state.anchorOrigin?.horizontal || 'center',
            vertical: state.anchorOrigin?.vertical || 'top',
          }}
          autoHideDuration={state.autoHideDuration}
        >
          <SnackbarContent
            message={state.message}
            classes={{
              root: classes[state.type],
              message: classes.message,
            }}
          />
        </Snackbar>
        {children}
      </Context.Provider>
    );
  };

  function showSnackbar(options: Options): void {
    dispatchAction({ type: Actions.OPEN, options });
  }

  return [Provider, (): any => useContext(Context), showSnackbar];
};

const [SnackbarProvider, useSnackbar, showSnackbar] = ctx();

export { SnackbarProvider, useSnackbar, showSnackbar };
