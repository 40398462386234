import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import api from 'api';
import { selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { ProfitabilityTab } from 'components/SupplierCard/Tabs/ProfitabilityTab/ProfitabilityTab';
import { startLoading, finishLoading } from 'hooks/useProgress';

export class SrmSupplierCardProfitabilityTabContainer extends React.Component {
  state = {
    tabData: [],
  };

  async componentDidMount() {
    this.requestData();
  }

  async componentDidUpdate(prevProps) {
    const { selectedSupplierId } = this.props;
    if (selectedSupplierId && prevProps.selectedSupplierId !== selectedSupplierId) {
      this.requestData();
    }
  }

  calculateTotal = () => {
    const { tabData } = this.state;
    this.setState(() => ({
      tabData: tabData.map(tabRow => ({
        ...tabRow,
        percent: tabRow.tb1 === 0 || tabRow.turnover === 0
          ? 0
          : parseFloat(((tabRow.tb1 / tabRow.turnover) * 100).toFixed(1)),
        turnover: Math.round(tabRow.turnover),
        tb1: Math.round(tabRow.tb1),
      })),
    }));
  };

  requestData = async () => {
    const { selectedSupplierId } = this.props;
    startLoading();
    try {
      const { profitability } = await api.getProfitability(selectedSupplierId);
      this.setState(() => ({
        tabData: profitability || [],
      }), this.calculateTotal);
    } catch (err) {
    }
    finishLoading();
  };

  render() {
    const { tabData } = this.state;
    return (
      <ProfitabilityTab
        tabData={tabData}
      />
    );
  }
}

const mapStateToProps = state => ({
  selectedSupplierId: selectSelectedSupplierId(state),
});

SrmSupplierCardProfitabilityTabContainer.propTypes = {
  selectedSupplierId: PropTypes.string.isRequired,
};

const ConnectedSrmSupplierCardProfitabilityTabContainer = connect(mapStateToProps)(
  SrmSupplierCardProfitabilityTabContainer,
);
export default ConnectedSrmSupplierCardProfitabilityTabContainer;
