import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Lock, LockOpenTwoTone, CheckOutlined, ClearOutlined } from '@material-ui/icons';
import api from 'api';
import { MyPageTable } from 'components/common/myPageTable/MyPageTable';
import { selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import { SupplierId } from 'store/suppliersList/types';
import { AppState } from 'store/RootReducer';

type MapStateToProps = {
  selectedSupplierId: SupplierId;
}

type SupplierNrTabProps = MapStateToProps;

const SupplierNrTabComponent = React.memo(({
  selectedSupplierId,
}: SupplierNrTabProps) => {
  const [data, setData] = useState([]);

  const requestData = useCallback(async () => {
    startLoading();
    try {
      const { supplierNrs } = await api.getSupplierNrs(selectedSupplierId);
      setData(supplierNrs);
    } catch (err) {
    }
    finishLoading();
  }, [selectedSupplierId]);

  useEffect(() => {
    if (selectedSupplierId) {
      requestData();
    }
  }, [requestData, selectedSupplierId]);

  return (
    <MyPageTable
      filter
      padding="small"
      tableData={data}
      columns={[
        { name: 'supplierVismaId', title: 'Visma ID' },
        { name: 'nickName', title: 'Name' },
        { name: 'companyName', title: 'Company' },
        { name: 'address1', title: 'Address1' },
        { name: 'address2', title: 'Address2' },
        { name: 'zip', title: 'Zip' },
        { name: 'city', title: 'City' },
        { name: 'country', title: 'Country' },
        { name: 'currency', title: 'Currency' },
        { name: 'creditTime', title: 'Credit Time' },
        { name: 'blocked', title: 'Blocked' },
        { name: 'transporeonVisibilityHub', title: 'Transporeon Visibility Hub' },
      ]}
      columnExtensions={[
        { columnName: 'supplierVismaId', width: 110 },
        { columnName: 'country', width: 110 },
        { columnName: 'currency', width: 110 },
        { columnName: 'creditTime', width: 120 },
        { columnName: 'blocked', width: 110, align: 'center' },
        { columnName: 'transporeonVisibilityHub', width: 110, align: 'center' },
      ]}
      formatBoolean={['blocked', 'transporeonVisibilityHub']}
      booleanIcons={[{
        fieldName:"blocked",
        yes: {
          icon: Lock,
          color: '#d50000',
        },
        no: {
          icon: LockOpenTwoTone,
          color: '#087f23',
        },
      },
      {
        fieldName:"transporeonVisibilityHub",
        yes: {
          icon: CheckOutlined,
          color: '#087f23',
        },
        no: {
          icon: ClearOutlined,
          color: '#d50000',
        },
      }]}
      defaultSorting={[{ columnName: 'nickName', direction: 'asc' }]}
    />
  );
});

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedSupplierId: selectSelectedSupplierId(state),
});

export const SupplierNrTab = connect(mapStateToProps)(SupplierNrTabComponent);
