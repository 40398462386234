import React, { useEffect, useState } from 'react';
import cn from "../../containers/MyPage/MyPage.module.scss";
import { MyPageTable } from "../common/myPageTable/MyPageTable";
import { finishLoading, startLoading } from "../../hooks/useProgress";
import { Customer } from 'store/suppliersList/types';
import api from 'api';

type MyPageData = {
    customers: Customer[];
};

export const MyCustomers = () => {
    const [data, setData] = useState<MyPageData>(
        {
            customers: []
        },
    );

    useEffect(() => {
        try {
            startLoading();
            (async (): Promise<void> => {
                let data = await api.getMyCustomers();
                setData(data);
            })();
        } catch (err) {
        }
        finishLoading();
    }, []);

    return (
        <div>
            <div className={cn.tableTitle}>MY CUSTOMERS</div>
            <MyPageTable
                className={cn.table}
                tableData={data.customers}
                columns={[
                    { name: 'customerCard', title: 'Customer Name' },
                    { name: 'turnover', title: 'Total Turnover [EUR]' },
                    { name: 'tb1', title: 'Total TB1 [EUR]' },
                    { name: 'nrOfShipments', title: 'Total nr of shipments' },
                    { name: 'percent', title: '%' },
                ]}
                columnExtensions={[
                    { columnName: 'customerCard', width: 270 },
                    { columnName: 'turnover', align: 'right' },
                    { columnName: 'tb1', align: 'right' },
                    { columnName: 'nrOfShipments', align: 'right' },
                    { columnName: 'percent', align: 'right' },
                ]}
                formatNumberWithSpace={['turnover', 'tb1']}
                data-role="customers-table"
            />
        </div>
    )
}