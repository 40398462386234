import React, { useEffect, useState } from 'react';
import SearchBar from 'material-ui-search-bar';
import cn from './SearchBar.module.scss';
import routeConstants from 'constants/Routes';
import { useHistory, useLocation } from 'react-router';
import {
    getSearchList,
    setSearchQuery as setSearchTerm
} from 'store/search/ActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsClaimsActive,
    selectIsSrmActive,
    selectQuery
} from 'store/search/Selector';
import { SearchTerm, SearchType } from 'store/search/types';

const SRMSearchBar = () => {
    const [term, setTerm] = useState('');
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const selectSearchTerm = useSelector(selectQuery);
    const srmState = useSelector(selectIsSrmActive);
    const claimsState = useSelector(selectIsClaimsActive);
    const searchParams = new URLSearchParams(location.search);

    function getSearchType() {
        return enumToBit(SearchType, [srmState, claimsState])
    }
    
    function enumToBit(enumeration: object, selection: boolean[]) {
        const bits = Object.keys(enumeration)
            .map(Number)
            .filter(Boolean)
        return selection.reduce(
            (total, selected, i) => total + (selected ? bits[i] : 0),
            0
        );
    }

    function handleTermChange(term: string): void {
        searchParams.set('query', term);
        searchParams.set('IsSrm', srmState.toString());
        searchParams.set('IsClaims', claimsState.toString());
        history.push({
            pathname: routeConstants.searchResult,
            search: searchParams.toString(),
        });
        setTerm(term);
        localStorage.setItem('lastSearchTerm', term);
        handleLocalStorage();
    };

    function handleSearchSubmit(): void {
        searchParams.set('query', term);
        searchParams.set('IsSrm', srmState.toString());
        searchParams.set('IsClaims', claimsState.toString());
        history.push({
            pathname: routeConstants.searchResult,
            search: searchParams.toString(),
        });
        const search: SearchTerm = {
            term: selectSearchTerm,
            searchType: getSearchType()
        }
        dispatch(getSearchList(search));
    }

    function handleLocalStorage() {
        let storageContent = localStorage.getItem('lastSearchTerm');
        if (storageContent) {
            setTerm(storageContent)
        }
    };

    function handleCancelSearch(): void {
        handleTermChange('');
    }

    useEffect(() => {
        setTerm(searchParams.get('query') ?? '');
        dispatch(setSearchTerm(term));
        const search: SearchTerm = {
            term: term,
            searchType: getSearchType()
        }
        const timer = setTimeout(() => {
            dispatch(getSearchList(search));
        }, 500);
        return () => clearTimeout(timer);
    }, [term]);

    useEffect(() => {
        const search: SearchTerm = {
            term: term,
            searchType: getSearchType()
        }
        dispatch(getSearchList(search));
    }, [srmState]);

    useEffect(() => {
        const search: SearchTerm = {
            term: term,
            searchType: getSearchType()
        }
        dispatch(getSearchList(search));
    }, [claimsState]);

    useEffect(() => {
        history.listen((location) => {
            if (location.pathname !== routeConstants.searchResult) {
                setTerm('');
            } else {
                setTerm(searchParams.get('query') ?? '');
            }
        })
    }, [history])

    useEffect(() => {
        if (window.location.pathname !== routeConstants.searchResult) {
            setTerm('');
            localStorage.setItem('lastSearchTerm', term);
        } else {
            setTerm(searchParams.get('query') ?? '');
        }
    }, [])

    return (
        <SearchBar
            value={term}
            className={cn.search}
            onChange={(term) => handleTermChange(term)}
            onRequestSearch={() => handleSearchSubmit()}
            onCancelSearch={() => handleCancelSearch()}
            style={{
                height: 30,
            }}
            classes={{
                searchContainer: cn.searchContainer,
             }}
        />

    )
};

export default SRMSearchBar;