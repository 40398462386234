import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  AppBar, Divider, Hidden, Toolbar,
} from '@material-ui/core';

import MenuIcon from 'img/ic-burger.svg';
import { LeftMenuDrawer } from 'containers/srm/leftMenuContainer/SrmLeftMenuContainer';
import { MenuAppBarContainer } from 'containers/common/MenuAppBarContainer';
import {
  getSuppliersList as getSuppliersListAction,
  selectSupplier as selectSupplierAction,
} from 'store/suppliersList/ActionCreator';
import { getResponsibleSales as getResponsibleSalesAction } from 'store/responsibleSales/Actions';
import { clickMenu as clickMenuAction } from 'store/actionBar/ActionCreator';
import { selectIsMenuOpened } from 'store/actionBar/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import { selectSelectedSupplierId } from '../../../store/suppliersList/Selector';
import cn from './SrmLayoutContainer.module.scss';

class SrmLayoutComponent extends React.PureComponent {
  componentDidMount() {
    const {
      getSuppliersList, getResponsibleSales
    } = this.props;
    const fetchData = async () => {
      startLoading();
      try {
        await Promise.all([getSuppliersList(), getResponsibleSales()]);
      } catch (err) {
      }
      finishLoading();
    };
    fetchData();
  }

  handleMenuClick = () => {
    const { clickMenu, isMenuOpen } = this.props;
    clickMenu(!isMenuOpen);
  };

  render() {
    const { isMenuOpen, toolbar, children } = this.props;
    return (
      <div className={cn.layoutWrapper}>
        <MenuAppBarContainer />
        <div className={cn.mainContentWrapper}>
          <LeftMenuDrawer />
          <div className={classNames(cn.rightContainer, { [cn.menuOpened]: isMenuOpen })}>
            <AppBar position="static" className={cn.appBarRoot}>
              <Toolbar className={cn.toolbarRoot}>
                <Hidden smUp={isMenuOpen}>
                  <button id="menuButton" type="button" onClick={this.handleMenuClick} className={cn.menuIconButton}>
                    <img src={MenuIcon} alt="MenuIcon" />
                  </button>
                </Hidden>
                {toolbar}
              </Toolbar>
              <Divider />
            </AppBar>
            <div className={cn.contentContainer}>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isMenuOpen: selectIsMenuOpened(state),
  supplierId: selectSelectedSupplierId(state),
});

const mapDispatchToProps = {
  getSuppliersList: getSuppliersListAction,
  getResponsibleSales: getResponsibleSalesAction,
  clickMenu: clickMenuAction,
  selectSupplier: selectSupplierAction,
};

SrmLayoutComponent.propTypes = {
  getSuppliersList: PropTypes.func,
  getResponsibleSales: PropTypes.func,
  clickMenu: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  toolbar: PropTypes.node,
  children: PropTypes.node,
};

export const SrmLayout = connect(mapStateToProps, mapDispatchToProps)(SrmLayoutComponent);
