export enum LogsActionsTypes {
  SET_LOGS = 'SET_LOGS',
}

export interface SetLogAction {
  type: typeof LogsActionsTypes.SET_LOGS;
  logs: Array<Log>;
}

export type LogsAction = SetLogAction;

export type LogsState = Array<Log>;

export interface Log {
  comment: string;
  createdAt: string;
  createdBy: string;
  supplierCardId: string | null;
  id?: string;
}

export type LogList = Array<Log>;
