import React from 'react';
import classNames from 'classnames';
import cn from './Disabling.module.css';

type DisablingProps = {
  isLoading: boolean;
  children: any;
}

export const Disabling = ({ isLoading, children }: DisablingProps) => (
  <div className={classNames(
    cn.mainContentGeneral,
    { [cn.disableContent]: isLoading },
  )}
  >
    {children}
  </div>
);

export default Disabling;
