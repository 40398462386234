import api from 'api';
import { ThunkAction } from '../StoreConfig';
import { ActionTypes, BusinessCaseStatusList, SetBusinessCaseStatusList, SetSelectedTab } from './types';

const setBusinessCaseStatusListSuccess =
  (statusList: BusinessCaseStatusList): SetBusinessCaseStatusList => ({
    type: ActionTypes.SET_BUSINESS_CASE_STATUS_LIST,
    statusList,
  });

export const setSelectedTab = (tab: number): SetSelectedTab => ({
  type: ActionTypes.SET_SELECTED_TAB,
  tab,
});

