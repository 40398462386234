import React, { useState } from 'react';
import { MenuAppBarDrawer } from 'components/MenuAppBar/MenuAppBarDrawer/MenuAppBarDrawer';
import { MenuAppBar } from 'components/MenuAppBar/MenuAppBar';

export const MenuAppBarContainer = React.memo(() => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = (): boolean => {
    setIsMenuOpen(!isMenuOpen);
    return true;
  };

  return (
    <div>
      <MenuAppBar
        handleMenuToggle={handleMenuToggle}
      />
      <MenuAppBarDrawer
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
      />
    </div>
  )
});
