import React, { useEffect, useState } from 'react';
import cn from "../../containers/MyPage/MyPage.module.scss";
import { MyPageTable } from "../common/myPageTable/MyPageTable";
import { finishLoading, startLoading } from "../../hooks/useProgress";
import api from 'api';
import { BusinessCase } from 'types/BusinessCase';

type MyPageData = {
    businessCases: BusinessCase[];
};


export const MyBusinessCases = () => {
    const [data, setData] = useState<MyPageData>(
        {
            businessCases: [],
        },

    );

    useEffect(() => {
        try {
            startLoading();
            (async (): Promise<void> => {
                let data = await api.getMyBusinessCases();
                setData(data);
            })();
        } catch (err) {
        }
        finishLoading();
    }, []);

    return (
        <div>
            <div className={cn.tableTitle}>MY BUSINESS CASES</div>
            <MyPageTable
                className={cn.table}
                tableData={data.businessCases}
                columns={[
                    { name: 'customerCard', title: 'Customer Name' },
                    { name: 'relation', title: 'Description' },
                    { name: 'caseValue', title: 'Case Value [EUR]' },
                    { name: 'startOfOperations', title: 'Start of operation' },
                    { name: 'status', title: 'Status' },
                ]}
                columnExtensions={[
                    { columnName: 'customerCard', width: 270 },
                    { columnName: 'caseValue', align: 'right' },
                ]}
                formatNumberWithSpace={['caseValue']}
                data-role="business-case-table"
            />
        </div>
    )
}