import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import { MyPageTable } from 'components/common/myPageTable/MyPageTable';
import { selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import { SupplierId } from 'store/suppliersList/types';
import { AppState } from 'store/RootReducer';
import api from 'api';
import { BusinessArea, BusinessAreasList } from 'types/types';
import cn from './BusinessAreaTab.module.scss';

interface MapStateToProps {
  selectedSupplierId: SupplierId;
}

type BusinessAreaTabProps = MapStateToProps;

interface Label {
  name: string;
  color: string;
}

function getAllProducts(tableData: BusinessAreasList): Array<{name: string; color: string}> {
  const products: Array<{name: string; color: string}> = [];
  tableData.forEach((tableItem: BusinessArea) => {
    tableItem.products
      .filter(product => product.shareOfValue > 0)
      .forEach(product => {
        if (!products.find(item => product.productName === item.name)) {
          products.push({
            name: product.productName,
            color: product.productColor,
          });
        }
      });
  });
  return products;
}

function calculateLabelsData(tableData: BusinessAreasList): { tableData: BusinessAreasList; labelsData: Array<Label> } {
  if (tableData.length === 0) {
    return {
      tableData: [],
      labelsData: [],
    };
  } else {
    const labelsData: Array<Label> = [];
    const products = getAllProducts(tableData);

    // generate labelsData
    for (let i = 0; i < products.length; i++) {
      labelsData.push({
        name: products[i].name,
        color: products[i].color,
      });
    }
    return {
      tableData: tableData.map(tableItem => ({
        ...tableItem,
        turnover: Math.round(tableItem.products.reduce((current, next) => current + next.shareOfValue, 0)),
      })),
      labelsData,
    };
  }
}

const BusinessAreaTabComponent = React.memo(({selectedSupplierId}: BusinessAreaTabProps) => {
  const [tableData, setTableData] = useState<BusinessAreasList>([]);
  const [labelsData, setLabelsData] = useState<Array<Label>>([]);
  
  const requestData = useCallback(async () => {
    startLoading();
    try {
      const { businessAreas } = await api.getBusinessArea(selectedSupplierId);
      const { tableData, labelsData } = calculateLabelsData(businessAreas);
      setTableData(tableData);
      setLabelsData(labelsData);
    } catch (err) {
    }
    finishLoading();
  }, [selectedSupplierId]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const doughnutsData = useMemo(() => {
    return tableData
      .filter(tableItem => tableItem.turnover && tableItem.turnover !== 0)
      .map(tableItem => {
        const filteredLabelsData = labelsData.filter(label => Boolean(
          tableItem.products.find(tableProduct => tableProduct.productName === label.name)));
        return {
          businessArea: tableItem.businessArea,
          labels: filteredLabelsData.map(label => label.name),
          data: tableItem.products
            .filter(product => product.shareOfValue > 0)
            .map(product => Math.round(product.shareOfValue)),
          colors: filteredLabelsData.map(label => label.color),
        }
      })
  }, [labelsData, tableData]);

  return (
    <div className={cn.content}>
      <div className={cn.chartContainer}>
        <div className={cn.chart}>
          {doughnutsData.map((doughnut, index) => (
            <div key={index} className={cn.doughnut}>
              <Doughnut
                height={100}
                width={140}
                data={
                  {
                    labels: doughnut.labels,
                    datasets: [
                      {
                        data: doughnut.data,
                        backgroundColor: doughnut.colors,
                        hoverBackgroundColor: doughnut.colors,
                      },
                    ],
                  }
                }
                options={{ legend: { display: false } }}
              />
              <div className={cn.chartSign}>{doughnut.businessArea}</div>
            </div>
          ))}
        </div>
        <div className={cn.footerRoot}>
          {labelsData.map(labelData => (
            <div className={cn.footerContent} key={labelData.name}>
              <div className={cn.colorBox} style={{ backgroundColor: labelData.color }} />
              <span className={cn.footerLabel}>{labelData.name}</span>
            </div>
          ))}
        </div>
      </div>
      <MyPageTable
        tableData={tableData}
        columns={[
          { name: 'businessArea', title: 'Business Area' },
          { name: 'turnover', title: 'Turnover [EUR]' },
          { name: 'nrOfShipments', title: 'Number of shipments' },
          { name: 'lastBooking', title: 'Last booking' },
        ]}
        columnExtensions={[
          { columnName: 'businessArea' },
          { columnName: 'turnover', align: 'right'},
          { columnName: 'nrOfShipments', align: 'right'},
          { columnName: 'lastBooking', align: 'right', width: 150 },
        ]}
        formatNumberWithSpace={['turnover', 'nrOfShipments']}
        padding="small"
        className={cn.table}
        defaultSorting={[{ columnName: 'businessArea', direction: 'asc' }]}
      />        
    </div>
  )
});

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedSupplierId: selectSelectedSupplierId(state),
});

export const BusinessAreaTab = connect(mapStateToProps)(BusinessAreaTabComponent);
