import React, { ChangeEvent, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@material-ui/core';
import { MaterialVirtualizedSelect } from 'components/common/VirtualizedSelect/MaterialVirtualizedSelect';
import { AppState } from 'store/RootReducer';
import cn from './NewSupplierModal.module.scss';


type NewSupplierModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (supplierName: string) => void;
}

export const NewSupplierModalComponent = React.memo(({
  isOpen,
  handleClose,
  handleSave,
}: NewSupplierModalProps) => {
  const [supplierName, setSupplierName] = useState('');
  const [supplierNameError, setSupplierNameError] = useState(false);

  const handleSupplierNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSupplierNameError(false);
    setSupplierName(value);
  };

  const handleSubmit = async (): Promise<void> => {
    if (!supplierName || supplierName.trim().length == 0) {
      setSupplierNameError(true);
    } else {
      let name = supplierName.trim();
      while (name[name.length - 1] === '.') name = name.slice(0, -1);
      try {
        await handleSave(name);
      } catch (err) {
        setSupplierNameError(true);
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      className={cn.dialog}
      classes={{
        root: cn.dialog,
        paper: cn.container,
      }}
    >
      <DialogTitle>Add New Supplier</DialogTitle>
      <DialogContent className={cn.content}>
        <TextField
          label="Supplier name"
          value={supplierName}
          onChange={handleSupplierNameChange}
          error={supplierNameError}
          fullWidth
          variant="outlined"
          className={cn.input}
          data-role="name-input"
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSubmit} data-role="submit-button">
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const NewSupplierModal = connect()(NewSupplierModalComponent);
