import React, { ChangeEvent } from 'react';

import { Button } from 'components/common/Button/Button';

import cn from './LogInput.module.scss';

type LogInputProps = {
  logInputValue: string;
  handleChangeLogInput: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleAddLog: () => void;
};

export const LogInput = React.memo(({
  logInputValue, handleChangeLogInput, handleAddLog,
}: LogInputProps) => (
  <div className={cn.searchForm}>
    <textarea
      placeholder="New Entry"
      className={cn.searchField}
      value={logInputValue}
      onChange={handleChangeLogInput}
    />
    <Button onClick={handleAddLog} className={cn.searchButton}>
      Add
    </Button>
  </div>
));
