import React, { ReactElement } from 'react';
import { Fade, CircularProgress } from '@material-ui/core';
import cn from './Progress.module.scss';

export const Progress = ({ isLoading }: {isLoading: boolean}): ReactElement => (
  <Fade
    in={isLoading}
    unmountOnExit
  >
    <CircularProgress className={cn.root} />
  </Fade>
);
