import React, { ReactElement } from 'react';
import constants from 'constants/Footer';
import cn from './Footer.module.scss';

export const Footer = (): ReactElement => (
  <footer className={cn.footer}>
    <span className={cn.footerText}>
      {constants.signature}
    </span>
  </footer>
);
