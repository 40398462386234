import React, { ChangeEvent } from 'react';
import CreateNewFolderOutlined from '@material-ui/icons/CreateNewFolderOutlined';

type CreateFolderButton = {
  onClick: (event: ChangeEvent<HTMLDivElement>) => void;
  className?: string;
}

export const CreateFolderButton = ({ onClick, className }: CreateFolderButton) => {
  function handleClick(event: any): void {
    event.stopPropagation();
    onClick(event);
  }

  return (
    <CreateNewFolderOutlined onClick={handleClick} htmlColor="#0072ba" className={className} />
  )
};
