import {
    ActionTypes,
    SearchResultList,
    SearchListState,
    SetSearchResultsListAction,
    SearchResult,
    SearchTerm,
    SetSearchTermAction,
    SetCrmActiveAction,
    SetSrmActiveAction,
    SetClaimsActiveAction,
    SearchType,
} from './types';
import { ThunkAction } from '../StoreConfig';
import api from '../../api';
import { startLoading, finishLoading } from 'hooks/useProgress';

var lastTerm: string;
var lastType: SearchType;

export type GetSearchList = () => ThunkAction<void, SetSearchResultsListAction>;

export const setSearchList = (searchList: SearchResultList, pageNumber: number): SetSearchResultsListAction => ({
    type: ActionTypes.SET_SEARCH_RESULT_LIST,
    searchList,
    pageNumber
});

export const setSearchQuery = (searhQuery: string): SetSearchTermAction => ({
    type: ActionTypes.SEARCH,
    query: searhQuery,
});

export const setCrmState = (prevState: boolean):  SetCrmActiveAction => ({
    type: ActionTypes.SET_ACTIVITY_CRM,
    crmActive: !prevState
});

export const setSrmState = (prevState: boolean):  SetSrmActiveAction => ({
    type: ActionTypes.SET_ACTIVITY_SRM,
    srmActive: !prevState
});

export const setClaimsState = (prevState: boolean): SetClaimsActiveAction => ({
    type: ActionTypes.SET_ACTIVITY_CLAIMS,
    claimsActive: !prevState,
});

export const getSearchList = (searhTerm: SearchTerm) => async (dispatch: (arg0: SetSearchResultsListAction) => void): Promise<void> => {
    if (searhTerm.term === "" || searhTerm.term.length <= 1) {
        return
    } else {
        try {
            if((lastTerm !== searhTerm.term) || (lastType !== searhTerm.searchType)) {
                startLoading();
                const { searchList, pageNumber } = await api.postSearchTerm(searhTerm);
                finishLoading();
                dispatch(
                    setSearchList(
                        searchList,
                        pageNumber
                    ),
                );
            }
            lastType = searhTerm.searchType
            lastTerm = searhTerm.term;
        } catch (error) {   
            finishLoading();
        }
    }
};