import api from 'api';
import { SupplierId } from 'store/suppliersList/types';
import { ThunkAction } from '../StoreConfig';
import { LogsAction, LogsActionsTypes, SetLogAction, Log } from './types';

const setLogs = (logs: Array<Log>): LogsAction => ({
  type: LogsActionsTypes.SET_LOGS,
  logs,
});

export const handleLogsDownload = (supplierId: SupplierId): ThunkAction<void, SetLogAction> =>
  async (dispatch): Promise<void> => {
  if (!supplierId) {
    return;
  }
  try {
    const { logs } = await api.getSupplierLogs(supplierId);
    dispatch(setLogs(logs));
  } catch (err) {
  }
};
