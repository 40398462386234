import { Action } from 'redux';

export interface ResponsibleSale {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  displayName: string;
  initials?: string;
}

export type ResponsibleSalesList = Array<ResponsibleSale>;

export type ResponsibleSalesState = ResponsibleSalesList;

export enum ActionTypes {
  SET_RESPONSIBLE_SALES = 'SET_RESPONSIBLE_SALES',
}

export interface SetResponsibleSalesAction extends Action<typeof ActionTypes.SET_RESPONSIBLE_SALES> {
  responsibleSales: ResponsibleSalesList;
}

export type ResponsibleSalesAction = SetResponsibleSalesAction;
