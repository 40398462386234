import React from 'react';
import cn from './LogList.module.scss';
import { LogList as Logs } from 'store/logs/types';

type LogListProps = {
  supplierLogs: Logs;
}

export const LogList = React.memo(({ supplierLogs }: LogListProps) => (
  <div>
    {supplierLogs.length === 0 && (
      <div className={cn.logEmptyText}>Empty</div>
    )}
    {supplierLogs.map(supplierLog => (
      <div className={cn.logMain} key={supplierLog.id}>
        <div className={cn.logHeader}>{supplierLog.createdBy}</div>
        <div className={cn.logHeaderTime}>{supplierLog.createdAt}</div>
        <div className={cn.logText}>{supplierLog.comment}</div>
      </div>
    ))}
  </div>
));
