export function storeSupplier(id: string): void {
  localStorage.setItem('supplier', id);
}

export function getSavedSupplier(): string | null {
  const supplier = localStorage.getItem('supplier');
  if (supplier === 'undefined' || supplier === 'null') {
    return null;
  }
  return localStorage.getItem('supplier');
}
