import api from 'api';
import { ThunkAction } from '../StoreConfig';
import { ActionTypes, ResponsibleSalesList, SetResponsibleSalesAction } from './types';

const setResponsibleSales = (responsibleSales: ResponsibleSalesList): SetResponsibleSalesAction => ({
  type: ActionTypes.SET_RESPONSIBLE_SALES,
  responsibleSales,
});

export const getResponsibleSales = (): ThunkAction<void, SetResponsibleSalesAction> =>
  async (dispatch): Promise<void> => {
    const {responsibleSales} = await api.getResponsibleSales();
    dispatch(setResponsibleSales(
      responsibleSales
        .map(sale => {
          if (sale.firstName && sale.lastName) {
            return {
              ...sale,
              displayName: `${sale.firstName} ${sale.lastName}`,
            }
          }
          return {
            ...sale,
            displayName: sale.userName,
          }
        })
        .sort((firstSale, secondSale) => {
          if (firstSale.displayName.toLowerCase() === secondSale.displayName.toLowerCase()) {
            return 0;
          }
          return firstSale.displayName.toLowerCase() < secondSale.displayName.toLowerCase() ? -1 : 1;
        })
    ));
  };

