import * as graph from '@microsoft/microsoft-graph-client';
import { useSelector } from 'react-redux';
import { AppState } from 'store/RootReducer';
import { selectSelectedSupplier } from 'store/suppliersList/Selector';
import { useMicrosoftAuth } from 'hooks/useMicrosoftAuth';
import { showSnackbar } from 'hooks/useSnackbar';

type UseFiles = {
  getFiles: (folderId?: number) => Promise<Array<DriveItem>>;
  downloadFile: (file: DriveItem) => Promise<void>;
  deleteFile: (file: DriveItem) => Promise<void>;
  uploadFile: (folderId: number, file: File, conflictBehavior?: ConflictBehavior) => Promise<any>;
  createFolderById: (folderId: number, folderName: string) => Promise<DriveItem>;
  createDefaultFolders: (supplierName: string) => Promise<{ id: string; children: DriveItem[] }>;
  createFolderByPath: (path: string, folderName: string, supplierName: string) => Promise<DriveItem>;
};

export type DriveItem = {
  id: number;
  name: string;
  webUrl: string;
  '@microsoft.graph.downloadUrl': string;
  file?: {
    mimeType: string;
  };
  folder?: {
    id: number;
  };
};

type ConflictBehavior = 'fail' | 'replace' | 'rename';

export function useFiles(): UseFiles {
  const supplier = useSelector(selectSelectedSupplier);
  const { getClient, getDriveId } = useMicrosoftAuth();

  async function getRootFolderObject(client: graph.Client, driveId: string): Promise<any> {
    if (!supplier) {
      return null;
    }

    try {
      const rootFolder = await client
        .api(`/drives/${driveId}/root:/${supplier.name}`)
        .get();
      return rootFolder;
    } catch (err) {
      if ((err as any).statusCode === 404) {
        return null;
      }
      throw err;
    }
  }

  async function getRootFolderItems(client: graph.Client, driveId: string): Promise<any> {
    if (!supplier) {
      return null;
    }
    const rootFolder = await getRootFolderObject(client, driveId);
    if (!rootFolder) {
      return [];
    }
    const { value: files } = await client
      .api(`/drives/${driveId}/items/${rootFolder.id}/children`)
      .get();
    return files;
  }

  async function getFiles(folderId?: number): Promise<Array<DriveItem>> {
    if (!supplier) {
      return [];
    }
    try {
      const client = await getClient();
      const driveId = await getDriveId();

      if (!folderId) {
        return await getRootFolderItems(client, driveId);
      }

      const { value } = await client
        .api(`/drives/${driveId}/items/${folderId}/children`)
        .get();
      return value;
    } catch (err) {
      return [];
    }
  }

  async function downloadFile(file: DriveItem): Promise<void> {
    const url = file['@microsoft.graph.downloadUrl'];
    window.open(url, '_self');
  }

  async function deleteFile(file: DriveItem): Promise<void> {
    try {
      const client = await getClient();
      const driveId = await getDriveId();
      await client
        .api(`/drives/${driveId}/items/${file.id}`)
        .delete();
    } catch (err) {
    }
  }

  async function uploadFile(folderId: number, file: File, conflictBehavior?: ConflictBehavior): Promise<any> {
    try {
      const client = await getClient();
      const driveId = await getDriveId();

      const uploadSession = await graph.LargeFileUploadTask.createUploadSession(
        client,
        `/drives/${driveId}/items/${folderId}:/${file.name}:/createUploadSession`,
        { '@microsoft.graph.conflictBehavior': conflictBehavior || 'replace' },
      );

      const fileObject = { content: file, name: file.name, size: file.size };
      const uploadTask = new graph.LargeFileUploadTask(client, fileObject, uploadSession);
      const result = await uploadTask.upload();
      showSnackbar({ type: 'success', message: 'File was uploaded successfully.' });
      return result;
    } catch (err) {
      showSnackbar({
        message: 'File could not be uploaded due to internal error. Please, contact your System Administrator.',
        type: 'error',
      });
      throw err;
    }
  }

  async function createFolderById(folderId: number, folderName: string): Promise<DriveItem> {
    const client = await getClient();
    const driveId = await getDriveId();

    return await client
      .api(`/drives/${driveId}/items/${folderId}/children`)
      .post({
        name: folderName,
        folder: {},
      });
  }

  async function createDefaultFolders(supplierName: string): Promise<{ id: string; children: DriveItem[] }> {
    const client = await getClient();
    const driveId = await getDriveId();

    let rootFolder = await getRootFolderObject(client, driveId);

    if (!rootFolder) {
      rootFolder = await client
        .api(`/drives/${driveId}/root/children`)
        .post({ name: supplierName, folder: {} });
    }

    const folders = [
      { name: '1. Insurance', folder: {} },
      { name: '2. Agreements', folder: {} },
      { name: '3. Statistics', folder: {} },
      { name: '4. Finance', folder: {} },
      { name: '5. Survey', folder: {} },
      { name: '6. Other', folder: {} },
    ];

    const results = await Promise.all(folders.map(async (folder) => await client
      .api(`/drives/${driveId}/items/${rootFolder.id}/children`)
      .post(folder)));

    return {
      ...rootFolder,
      children: results,
    };
  }

  async function createFolderByPath(path: string, folderName: string, supplierName: string): Promise<DriveItem> {
    const pathParts = path.split('/').filter((part) => part);

    const client = await getClient();
    const driveId = await getDriveId();

    let rootFolder = await getRootFolderObject(client, driveId);

    if (!rootFolder) {
      rootFolder = await createDefaultFolders(supplierName);
    }

    const promises = pathParts.map((pathPart: string): (rootFolder: DriveItem) => Promise<DriveItem> => async (rootFolder): Promise<DriveItem> => {
      const { value } = await client.api(`/drives/${driveId}/items/${rootFolder.id}/children`).get();
      const pathFolder = value.find((folder: DriveItem) => folder.name === pathPart);
      if (!pathFolder) {
        return await client.api(
          `/drives/${driveId}/items/${rootFolder.id}/children`,
        ).post({ name: pathPart, folder: {} });
      }
      return pathFolder;
    });

    for (const x of promises) {
      rootFolder = await x(rootFolder);
    }

    const { value: children } = await client.api(`/drives/${driveId}/items/${rootFolder.id}/children`).get();
    const existingFolder = children.find((folder: DriveItem) => folder.name === folderName);

    if (existingFolder) {
      return existingFolder;
    }

    return await client.api(
      `/drives/${driveId}/items/${rootFolder.id}/children`,
    ).post({ name: folderName, folder: {} });
  }

  return {
    getFiles,
    downloadFile,
    deleteFile,
    uploadFile,
    createFolderById,
    createDefaultFolders,
    createFolderByPath,
  };
}
